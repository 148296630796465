import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { RealEstateRequestStatementsRepository } from '@data/repository/real-estate-request-statements.repository';
import { StatementType, AttachmentType } from '@proxy/enums';
import { AttachmentInput } from '@data/model';
import { IAttachment } from '@spga-shared/components';
import { Observable } from 'rxjs';
import { StatementAttachmentDto } from '@proxy/real-estate-ownership-requests-statements';
import { IFormFile } from '@proxy/microsoft/asp-net-core/http';
import { ToasterService } from '@abp/ng.theme.shared';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { WhitespaceValidator } from '@spga-shared/validators';

@Component({
  selector: 'app-request-inquiry',
  templateUrl: './request-inquiry.component.html',
  styleUrls: ['./request-inquiry.component.scss']
})
export class RequestInquiryComponent implements OnInit {
  dropdownList :any = [];
  selectedItems :any  = [];
  dropdownSettings :any  = {};
  menuDetailsWithOptions:any = [];
  selectedOptions:any=[];

  @Input() requestId!: number;
  @Input() type!: StatementType[];
  @Input() inquiryTitle?: string;
  @Input() isChangeEngOffice?: boolean | null = null;
  count:number = 1
  attachmentsInputs: AttachmentInput[] = Array.from({ length: 10 }, () => ({
    attachmentType: AttachmentType.SupportingDocuments,
    title: 'محتوى نصي للمرفق', optional: true , count: this.count++
  }));
  uploadFn!: (formData: IFormFile) => Observable<any>;
  isFormVisible = false;
  form?: UntypedFormGroup;
  isSubmitting = false;
  isSave = false;
  statementTypeList = StatementType;
  isToSPGAPublicUser:boolean = false;
  haveAttachments:boolean = false;

  constructor(
    private repository: RealEstateRequestStatementsRepository,
    private toaster: ToasterService
  ) {
  }

  ngOnInit(): void {  
    this.uploadFn = this.uploadFn = (formData: IFormFile) => this.repository.createStatementAttachmentByFile(formData);
    this.repository.getMenuDetailsWithOptions(this.type[0]).subscribe((x)=>{
      this.menuDetailsWithOptions = x;
    });
        
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'أختيار الكل',
      unSelectAllText: 'حذف الكل',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      defaultOpen:false,
      enableCheckAll:false,
      searchPlaceholderText:"بحث",
    };

    this.selectedOptions=[];
    this.isToSPGAPublicUser = this.statementTypeList[this.type[0]]?.includes('ToSPGAPublicUser') && this.statementTypeList[this.type[0]] != 'FromEngineeringOfficeToSPGAPublicUser';
  }

  onItemSelect(item: any) {
    this.selectedOptions.push(item.id);
  }
  onItemDeSelect(item: any) {
    let itemIndex = this.selectedOptions.indexOf(item.id);
    this.selectedOptions.splice(itemIndex,1);
  }
  onSelectAll(items: any[]) {
    items.forEach(item => {
      this.selectedOptions.push(item.id);
    });
  }
  onDeSelectAll()
  {
    this.selectedOptions=[];
  }

  showForm(): void {
    this.isFormVisible = true;
    this.buildForm();
  }

  hideForm(): void {
    this.isFormVisible = false;
    this.form?.reset();
    this.selectedOptions=[];
    this.count = 1  
    this.attachmentsInputs = []
    this.attachmentsInputs = Array.from({ length: 10 }, () => ({
      attachmentType: AttachmentType.SupportingDocuments,
      title: 'محتوى نصي للمرفق', optional: true , count: this.count++
    }));
    this.haveAttachments = this.attachmentsInputs.some(x => x.attachment)
  }

  buildForm(): void {
    if(this.menuDetailsWithOptions.length)
    {
    this.form = new UntypedFormGroup({
        other: new UntypedFormControl('', [Validators.maxLength(250)]),
        statement: new UntypedFormControl('', [Validators.maxLength(4000)])
    });
  }
  else
  {
    this.form = new UntypedFormGroup({
      other: new UntypedFormControl('', [Validators.maxLength(250)]),
      statement: new UntypedFormControl('', [Validators.maxLength(4000), Validators.required, WhitespaceValidator()])
  });
  }
  if(this.isToSPGAPublicUser)
  this.fillForm()
  }

  fillForm() {
    this.repository.getSavedBeneficiaryStatement(
      this.requestId
    ).subscribe(value => {
      if (!this.menuDetailsWithOptions.length)
        this.form?.get('statement')!.patchValue(value.statementText);
      else
        this.form?.get('other')!.patchValue(value.statementText);

      this.attachmentsInputs.forEach(x => {
        const attachments = value?.attachmentsUrls?.filter((a: { attachmentType: AttachmentType }) => (a.attachmentType === x.attachmentType));
        if (attachments?.length) {
          x.attachment = attachments[(x.count || 1) - 1] as any;
        }
      });
      this.haveAttachments = this.attachmentsInputs.some(x => x.attachment)
    })
  }

  onSave(){
    if(!this.isToSPGAPublicUser) return;
    this.form?.controls.statement.disable();
    this.isSave = true;
    this.repository.createSavedBeneficiaryStatement({
      requestId: this.requestId,
      statementText: this.form?.controls.statement.value,
      other: this.form?.controls.other.value,
      selectedOptions:this.selectedOptions,
      attachmentsUrls: this.attachmentsInputs.map(x => x.attachment).filter(x => x) as StatementAttachmentDto[]
    })
      .pipe(
        finalize(() => {
          this.form?.controls.statement.enable();
          this.isSubmitting = false;
          this.isSave = false;
        })
      )
      .subscribe(() => {
        this.toaster.success('تم الحفظ بنجاح');
        this.hideForm();
      });
  }

  submitForm(): void {
    if(!this.selectedOptions.length&&!this.form?.value.statement.length&&!this.form?.value.other.length)
    {
      this.toaster.error('يجب تحديد نص الافادة');
      return;
    }
    this.form?.controls.statement.disable();
    this.isSubmitting = true;
    this.repository.create({
      managerStatement:"",
      requestId: this.requestId,
      statement: this.form?.controls.statement.value,
      other:this.form?.controls.other.value,
      type:this.type[0],
      attachmentsUrls: this.attachmentsInputs.map(x => x.attachment).filter(x => x) as StatementAttachmentDto[],
      selectedOptions:this.selectedOptions,
      isChangeEngOffice: this.isChangeEngOffice
    })
      .pipe(
        finalize(() => {
          this.form?.controls.statement.enable();
          this.isSubmitting = false;
          this.isSave = false;
        })
      )
      .subscribe(() => {
        this.hideForm();
        // TODO: Handle redirect work
      });
  }

  handleAttachment(attachmentInput: AttachmentInput, attachment: IAttachment): void {    
    attachmentInput.attachment = attachment as StatementAttachmentDto;
    this.haveAttachments = this.attachmentsInputs.some(x => x.attachment)
  }
}
