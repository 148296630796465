import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ABP, eLayoutType, PermissionGuard } from '@abp/ng.core';
import { LoginGuard } from '@spga-shared/providers/guard/login.guard';
import { defaultErrorPage } from '@spga-shared/components';
import { TransferPublicRequestGuard } from '@spga-shared/providers';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'requests'
  },
  {
    path: 'requests',
    canActivate: [LoginGuard, PermissionGuard],
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: {
      routes: [
        {
          path: '/requests',
          name: '::Menu:Home',
          iconClass: 'fas fa-home',
          order: 1,
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'engineering-offices',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./engineering-office/engineering-office.module').then(m => m.EngineeringOfficeModule),
    data: {
      routes: [
        {
          path: '/engineering-offices',
          name: '::Menu:engineering-offices',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'bills-setting',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./bills-setting/bills-setting.module').then(m => m.BillsSettingModule),
    data: {
      routes: [
        {
          path: '/bills-setting',
          name: '::Menu:bills-setting',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'balady-zone',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./balady/balady.module').then(m => m.BaladyModule),
    data: {
      routes: [
        {
          path: '/balady-zone',
          name: '::Menu:balady-zone',
          layout: eLayoutType.application
        }
      ] as ABP.Route[],
      roles: ["admin"] as string[]
    }
  },
  {
    path: 'account',
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import('@abp/ng.account').then((m) => m.AccountModule.forLazy({ redirectUrl: '/' }))
  },
  {
    path: 'identity',
    canActivate: [PermissionGuard],
    loadChildren: () => import('@abp/ng.identity').then((m) => m.IdentityModule.forLazy())
  },
  {
    path: 'tenant-management',
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import('@abp/ng.tenant-management').then((m) => m.TenantManagementModule.forLazy())
  },
  {
    path: 'setting-management',
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import('@abp/ng.setting-management').then((m) => m.SettingManagementModule.forLazy())
  },
  {
    path: 'statement-management',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () =>
      import('./statement-administration/statement-administration.module').then(m => m.StatementAdministrationModule),
    data: {
      routes: [
        {
          path: '/statement-management',
          name: '::Menu:statement-management',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'transfer-public-request',
    canActivate: [LoginGuard, PermissionGuard, TransferPublicRequestGuard],
    loadChildren: () =>
      import('./transfer-public-request/transfer-public-request.module').then(m => m.TransferPublicRequestModule),
    data: {
      routes: [
        {
          path: '/transfer-public-request',
          name: '::Menu:transfer-public-request',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'reassign-requests',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () =>
      import('./reallocate-requests/reallocate-requests.module').then(m => m.ReallocateRequestsModule),
    data: {
      routes: [
        {
          path: '/reassign-requests',
          name: '::Menu:reassign-requests',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'reassign-status',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () =>
      import('./reallocate-status/reallocate-status.module').then(m => m.ReallocateRequestsModule),
    data: {
      routes: [
        {
          path: '/reassign-status',
          name: '::Menu:reassign-status',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  
  {
    path: 'real-estate-requests/:type',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () =>
      import('./real-estate-requests/real-estate-requests.module').then(m => m.RealEstateRequestsModule),
    data: {
      routes: [
        {
          path: '/real-estate-requests',
          name: '::Menu:real-estate-requests',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'real-estate-requests/:type/:path',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () =>
      import('./real-estate-requests/real-estate-requests.module').then(m => m.RealEstateRequestsModule),
    data: {
      routes: [
        {
          path: '/real-estate-requests',
          name: '::Menu:real-estate-requests',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [LoginGuard, PermissionGuard],
    data: {
      //requiredPolicy: 'SPGA.UsersComponent', // policy key for your component
      routes: [
        {
          path: '/users',
          name: '::Menu:users',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'area-report', loadChildren: () => import('./area-report/area-report.module').then(m => m.AreaReportModule),
    canActivate: [LoginGuard, PermissionGuard],
    data: {
      //requiredPolicy: 'SPGA.UsersComponent', // policy key for your component
      routes: [
        {
          path: '/area-report/success',
          name: 'area-report-success',
          layout: eLayoutType.application
        },
        {
          path: '/area-report',
          name: '::Menu:area_reports',
          layout: eLayoutType.empty
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'real-estate-engineering-office-tasks',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./real-estate-engineering-office-tasks/real-estate-engineering-office-tasks.module').then(m => m.RealEstateEngineeringOfficeTasksModule),
    data: {
      routes: [
        {
          path: '/real-estate-engineering-office-tasks',
          name: '::Menu:real-estate-engineering-office-tasks',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'real-estate-ads',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./real-estate-ads/real-estate-ads.module').then(m => m.RealEstateAdsModule),
    data: {
      routes: [
        {
          path: '/real-estate-ads',
          name: '::Menu:real-estate-ads',
          layout: eLayoutType.application
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'request-preview',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./request-preview/request-preview.module').then(m => m.RequestPreviewModule),
    data: {
      routes: [
        {
          path: '/request-preview',
          name: '::Menu:request-preview',
          layout: eLayoutType.empty
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'request-preview/:statementType',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./request-preview/request-preview.module').then(m => m.RequestPreviewModule),
    data: {
      routes: [
        {
          path: '/request-preview',
          name: '::Menu:request-preview',
          layout: eLayoutType.empty
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'notes-report/:requestId',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./notes-report/notes-report.module').then(m => m.NotesReport),
    data: {
      routes: [
        {
          path: '/notes-report',
          name: '::Menu:notes-report',
          layout: eLayoutType.empty
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'secretary-report/:requestId',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./secretary-notes/secretary-notes.module').then(m => m.SecretaryNotesModule),
    data: {
      routes: [
        {
          path: '/secretary-report',
          name: '::Menu:secretary-report',
          layout: eLayoutType.empty
        }
      ] as ABP.Route[]
    }
  },
  {
    path: 'engineering-office-edit-profile',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./engineering-office-edit-profile/engineering-office-edit-profile.module').then(m => m.EngineeringOfficeEditProfileModule),
    data: {
      routes: {
        path: '/engineering-office-edit-profile',
        name: 'engineering-office-edit-profile',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'objections', loadChildren: () => import('./objections/objections.module').then(m => m.ObjectionsModule),
    canActivate: [LoginGuard, PermissionGuard],
    data: {
    routes: [
      {
        path: '/objections',
        name: '::Menu:objections',
        layout: eLayoutType.application
      }
    ] as ABP.Route[]
  }},
  {
    path: 'objection-preview',
    canActivate: [LoginGuard, PermissionGuard],
    loadChildren: () => import('./objection-preview/objection-preview.module').then(m => m.ObjectionPreviewModule),
    data: {
      routes: [
        {
          path: '/objection-preview',
          name: '::Menu:objection-preview',
          layout: eLayoutType.empty
        }
      ] as ABP.Route[]
    }
},
{
  path: 'error403',
  component: defaultErrorPage,
  data: {
    routes: {
      path: '/error403',
      name: '::Menu:error403',
      layout: eLayoutType.empty
    } as ABP.Route
  }
},
{
  path: 'error405',
  component: defaultErrorPage,
  data: {
    routes: {
      path: '/error405',
      name: '::Menu:error405',
      layout: eLayoutType.empty
    } as ABP.Route
  }
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
