import { Component } from '@angular/core';
import { memberVoteOptions, MemberVote, formalGovOrganizationNameOptions, mainReserveMemberOptions } from '@spga-shared/proxy/enums';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { arabicCharsOnlyValidator } from '@spga-shared/validators';
import { LocalizationService } from '@abp/ng.core';
@Component({
  selector: 'app-new-vote-form',
  templateUrl: './vote-form.component.html',
  styleUrls: ['./vote-form.component.scss'],
})
export class VoteFormComponent {
  form!: UntypedFormGroup;
  //options
  memberVote = memberVoteOptions
  //enum
  MemberVote = MemberVote
  formalGovOrganizationName = formalGovOrganizationNameOptions;
  mainReserveMember = mainReserveMemberOptions
  constructor(private fb: UntypedFormBuilder , private localizationService: LocalizationService) {   this.buildForm();}
  buildForm(): void {
    this.form = this.fb.group({
      name: new UntypedFormControl(null, [Validators.required , Validators.maxLength(250) , arabicCharsOnlyValidator()]),
      formalGovOrganizationName: new UntypedFormControl(null, [Validators.required]),
      formalGovMainReserveMember:new UntypedFormControl(null , Validators.required),
      vote: new UntypedFormControl(null, [Validators.required]),
      disapprovalReason: new UntypedFormControl(null),
      formalGovAbstainingReason:new UntypedFormControl(null)
    });
  }
  counter(i: number) {
    return new Array(i);
  }
  memberVoteChange(ev:any){
    this.clearValidations()
    if(ev.value === MemberVote.Disapprove){
      this.form.controls.disapprovalReason.setValidators([Validators.required ,  Validators.maxLength(250)]);
      this.form.controls.disapprovalReason.updateValueAndValidity();
    }
    else if(ev.value === MemberVote.Abstaining) {
      this.form.controls.formalGovAbstainingReason.setValidators([Validators.required ,  Validators.maxLength(250)]);
      this.form.controls.formalGovAbstainingReason.updateValueAndValidity();
    }
  }

  clearValidations(){
    this.form.controls.disapprovalReason.clearValidators();
    this.form.controls.disapprovalReason.reset();
    this.form.controls.disapprovalReason.updateValueAndValidity();
    this.form.controls.formalGovAbstainingReason.clearValidators();
    this.form.controls.formalGovAbstainingReason.reset();
    this.form.controls.formalGovAbstainingReason.updateValueAndValidity();
  }

  translateSelectedValue(enumClass:string ,selectedValues:Array<number>):string{
    let translatedSelectedValue:string =""
    if(selectedValues[0]){
      selectedValues?.forEach((value , index) => {
        translatedSelectedValue += this.localizationService.instant(enumClass + value)
        if (index < selectedValues.length -1){
          translatedSelectedValue += ","
        }
      })
    }
    return translatedSelectedValue
  }

}
