import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Inject, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { AccountConfigModule } from '@abp/ng.account/config';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { NgxsModule } from '@ngxs/store';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { PageNotFoundComponent } from '@spga-shared/components';
import { registerLocale } from '@abp/ng.core/locale';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale()
    }),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: PageNotFoundComponent,
          hideCloseIcon: true,
          forWhichErrors: [404, 403]
        }
      }
    }),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    NgxsModule.forRoot(),
    ThemeBasicModule.forRoot(),
    SharedModule.forRoot(),
    ApmModule,
  ],
  providers: [
    ApmService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
