import { IEnvironment } from './environment.model';


// const baseUrl = 'http://localhost:4200';


// export const environment = {

//   production: false,

//   application: {

//     baseUrl,

//     name: 'SPGA',

//     logoUrl: 'assets/images/logo.svg'

//   },

//   oAuthConfig: {

//     issuer: 'https://localhost:44306',

//     redirectUri: baseUrl,

//     clientId: 'SPGA_App',

//     responseType: 'code',

//     scope: 'offline_access SPGA'

//   },

//   apis: {

//     default: {

//       url: 'https://localhost:44360',

//       rootNamespace: 'SPGA'

//     }

//   },

//   localization: {

//     defaultResourceName: 'SPGA'

//   },

//   adminSessionTimeInMin: 60

// } as IEnvironment;

interface environment extends IEnvironment {

} export const environment = (window as any)['dynamic_admin_env'] as environment; 
