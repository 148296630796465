import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObjectionInForm,ObjectionDetailsPerRequest } from '@data/model';
import { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import {
  AdminObjectionsRepository,
  RealEstateRequestAdsObjectionRepository
} from '@data/repository';
import { IAttachment } from '@spga-shared/components';
import { AppAuthService } from '@spga-shared/providers';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-objection-details',
  templateUrl: './objection-details.component.html',
  styleUrls: ['./objection-details.component.scss']
})
export class ObjectionDetailsComponent implements OnInit {
  @Input() objectionId!: number;
  @Input() objection?: ObjectionInForm;
  @Input() multiObjection: boolean = false;
  @Input() isInAds: boolean = false;
  requestId!: number;
  objections!: PagedResultDto<ObjectionInForm>;
  page = 1;
  pageSize = 3;
  params = new PagedAndSortedResultRequestDto({
    maxResultCount: 1
  }) as ObjectionDetailsPerRequest;
  isAdminMember = false;
  isAuditCommitteeSecretary = false;
  isSecretaryRequests = false;
  downloadAttachmentFn: (attachment: IAttachment) => Observable<any>;
  constructor(
    private route: ActivatedRoute,
    private appAuthService: AppAuthService,
    private repository: AdminObjectionsRepository,
    private realEstateRequestAdsObjectionRepository: RealEstateRequestAdsObjectionRepository
  ) {
    this.downloadAttachmentFn = (attachment: IAttachment) =>
      this.realEstateRequestAdsObjectionRepository.downloadAttachment(
        attachment.id!,
        attachment.name!
      );
  }

  ngOnInit(): void {
    if(!this.multiObjection){
      this.objectionId = +this.route.snapshot.params.objectionId;
      this.isAdminMember = this.appAuthService.checkIfUserHavePermission(
      'SPGA.Requests.AdminRequests'
    );
      this.isAuditCommitteeSecretary = this.appAuthService.checkIfUserHavePermission(
      'SPGA.Requests.ApprovedRequests'
    );
      if (this.isAdminMember) {
        this.repository.get(this.objectionId).subscribe(value => {
        this.objection = value;
      });
    }
    }
    else
    {
      this.requestId = +this.route.snapshot.params.requestId;
      this.isAuditCommitteeSecretary = this.appAuthService.checkIfUserHavePermission('SPGA.Requests.ApprovedRequests');
      this.isSecretaryRequests = this.appAuthService.checkIfUserHavePermission('SPGA.Requests.SecretaryRequests');
      if(this.isAuditCommitteeSecretary || this.isSecretaryRequests)
      {
        this.repository.getObjectionDetailsList({ maxResultCount: 1, requestId: this.requestId }).subscribe(value => {
          this.objections = value;
        });
      }
    }
  }

  pageChange(page: number) {
    this.params.skipCount = (page - 1) * this.params.maxResultCount;
    this.repository.getObjectionDetailsList({maxResultCount:this.params.maxResultCount , requestId:this.requestId ,skipCount:(page - 1) * this.params.maxResultCount}).subscribe(data => this.objections=data)
  }
}
