<div class="request-preview" *ngIf="!multiObjection">
    <ng-container>
        <h2 class="fields-title">تفاصيل الاعتراض</h2>
        <div class="row">
            <div *ngIf="objection?.objectionId" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم طلب الاعتراض</h2>
                    <p class="field__data">
                        {{objection?.objectionId}}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>

            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">اسم المعترض</h2>
                    <p class="field__data word-tram">
                        <i class="icon icon-user"></i> {{objection?.objectorName}}
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم هوية/سجل المعترض</h2>
                    <p class="field__data word-tram">
                        <i class="icon icon-user"></i> {{objection?.objectorIdentificationNumber}}
                    </p>
                </div>
            </div>
        </div>

        <div class="row">

            <div *ngIf="objection?.objectorRepresentativeName" class="col-md-6">
                <div class="field">
                    <h2 class="field__label">اسم ممثل الاعتراض</h2>
                    <p class="field__data word-tram">
                        <i class="icon icon-id-card-long"></i> {{objection?.objectorRepresentativeName}}
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">صفة المعترض</h2>
                    <p class="field__data">
                        {{ ('::Enum:ObjectorAdjactives:' + objection?.objectorAdjactive) | abpLocalization }}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>

            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">نوع المعترض</h2>
                    <p class="field__data">
                        {{ ('::Enum:ObjectorTypes:' + objection?.objectorType) | abpLocalization }}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>
            <div *ngIf="objection?.objectorRepresentativeIdentificationNumber" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم هوية ممثل مقدم الاعتراض </h2>
                    <p class="field__data">
                        <i class="icon icon-user"></i> {{objection?.objectorRepresentativeIdentificationNumber}}
                    </p>
                </div>
            </div>


        </div>


        <div class="row">

            <div *ngIf="objection?.instrumentNumber" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم الصك </h2>
                    <p class="field__data">
                        <i class="icon icon-id-card-long"></i> {{objection?.instrumentNumber}}
                    </p>
                </div>
            </div>

            <div *ngIf="objection?.agentNumber" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم الوكالة</h2>
                    <p class="field__data">
                        <i class="icon icon-id-card-long"></i> {{objection?.agentNumber}}
                    </p>
                </div>
            </div>

            <div *ngIf="objection?.objectionType" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">نوع الاعتراض</h2>
                    <p class="field__data">
                        {{ (('::Enum:ObjectionTypes:' + objection?.objectionType)|abpLocalization) }}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>

        </div>
        <div class="row">

            <div *ngIf="objection?.objectionDetails" class="col-md-8">
                <div class="field">
                    <h2 class="field__label">تفاصيل الاعتراض</h2>
                    <p class="field__data">
                        <i class="icon icon-id-card-long"></i> {{objection?.objectionDetails}}
                    </p>
                </div>
            </div>
        </div>
        
    </ng-container>
    <hr />
    <div class="attachments mt-4">
        <h2 class="app-section-title mb-4">مرفقات العقار</h2>
        <div *ngIf="objection?.attachmentsUrls?.length;else noAttachments" class="row">
            <div *ngFor="let attachment of objection?.attachmentsUrls" class="col-md-3">
                <app-custom-file-upload [attachmentInput]="attachment" [downloadFn]="downloadAttachmentFn"
                    [hasDate]="false"></app-custom-file-upload>
            </div>
        </div>
        <ng-template #noAttachments>
            <div class="alert alert-info">لا توجد مرفقات علي العقار</div>
        </ng-template>
    </div>
    <hr />
    <ng-container>
        <div class="row">
            <section class="app-section">
                <h2 class="app-section-title mb-4">الاقرارات</h2>
                <div class="app-sub-title font-weight-bold mb-4 custom-control custom-checkbox">
                    <input class="custom-control-input" id="customCheck1" type="checkbox" disabled checked>
                <label class="custom-control-label font-weight-bold" for="customCheck1">اقر بصحة البيانات المدخلة في
                    هذا النموذج وأتحمل المسئولية إن ظهر خلاف ذلك
                </label>
                </div>
            </section>
        </div>
        </ng-container>


</div>
<div class="request-preview" *ngIf="multiObjection" >
<ng-container *ngFor="let objection of objections.items">
        <h2 class="fields-title">بيانات الإعتراض</h2>
        <div class="row">
            <div *ngIf="objection?.objectionId" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم طلب الاعتراض</h2>
                    <p class="field__data">
                        {{objection?.objectionId}}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>

            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">اسم المعترض</h2>
                    <p class="field__data word-tram">
                        <i class="icon icon-user"></i> {{objection?.objectorName}}
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم هوية/سجل المعترض</h2>
                    <p class="field__data word-tram">
                        <i class="icon icon-user"></i> {{objection?.objectorIdentificationNumber}}
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="objection?.objectionStatus" class="col-md-4">
                        <div class="field">
                            <h2 class="field__label">حالة الإعتراض</h2>
                            <p class="field__data">
                                {{ (('::Enum:ObjectionStatuses:' + objection?.objectionStatus)|abpLocalization) }}
                                <i class="icon icon-id-card-long"></i>
                            </p>
                        </div>
            </div>
            <div *ngIf="objection?.objectorRepresentativeName" class="col-md-6">
                <div class="field">
                    <h2 class="field__label">اسم ممثل الاعتراض</h2>
                    <p class="field__data word-tram">
                        <i class="icon icon-id-card-long"></i> {{objection?.objectorRepresentativeName}}
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">صفة المعترض</h2>
                    <p class="field__data">
                        {{ ('::Enum:ObjectorAdjactives:' + objection?.objectorAdjactive) | abpLocalization }}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>

            <div class="col-md-4">
                <div class="field">
                    <h2 class="field__label">نوع المعترض</h2>
                    <p class="field__data">
                        {{ ('::Enum:ObjectorTypes:' + objection?.objectorType) | abpLocalization }}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>
            <div *ngIf="objection?.objectorRepresentativeIdentificationNumber" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم هوية ممثل مقدم الاعتراض </h2>
                    <p class="field__data">
                        <i class="icon icon-user"></i> {{objection?.objectorRepresentativeIdentificationNumber}}
                    </p>
                </div>
            </div>


        </div>


        <div class="row">

            <div *ngIf="objection?.instrumentNumber" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم الصك </h2>
                    <p class="field__data">
                        <i class="icon icon-id-card-long"></i> {{objection?.instrumentNumber}}
                    </p>
                </div>
            </div>

            <div *ngIf="objection?.agentNumber" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">رقم الوكالة</h2>
                    <p class="field__data">
                        <i class="icon icon-id-card-long"></i> {{objection?.agentNumber}}
                    </p>
                </div>
            </div>

            <div *ngIf="objection?.objectionType" class="col-md-4">
                <div class="field">
                    <h2 class="field__label">نوع الاعتراض</h2>
                    <p class="field__data">
                        {{ (('::Enum:ObjectionTypes:' + objection?.objectionType)|abpLocalization) }}
                        <i class="icon icon-id-card-long"></i>
                    </p>
                </div>
            </div>

        </div>
        <div class="row">

            <div *ngIf="objection?.objectionDetails" class="col-md-8">
                <div class="field">
                    <h2 class="field__label">تفاصيل الاعتراض</h2>
                    <p class="field__data">
                        <i class="icon icon-id-card-long"></i> {{objection?.objectionDetails}}
                    </p>
                </div>
            </div>
        </div>
        
    
    <hr />
    <div class="attachments mt-4">
        <h2 class="app-section-title mb-4">مرفقات الإعتراض</h2>
        <div *ngIf="objection?.attachmentsUrls?.length;else noAttachments" class="row">
            <div *ngFor="let attachment of objection?.attachmentsUrls" class="col-md-3">
                <app-custom-file-upload [attachmentInput]="attachment" [downloadFn]="downloadAttachmentFn"
                    [hasDate]="false"></app-custom-file-upload>
            </div>
        </div>
        <ng-template #noAttachments>
            <div class="alert alert-info">لا توجد مرفقات علي الإعتراض</div>
        </ng-template>
    </div>
</ng-container>
<hr />
<ng-container *ngIf="!isInAds">
        <div class="row">
            <section class="app-section">
                <h2 class="app-section-title mb-4">الإقرارات</h2>
                <div class="app-sub-title font-weight-bold mb-4 custom-control custom-checkbox">
                    <input class="custom-control-input" id="customCheck1" type="checkbox" disabled checked>
                <label class="custom-control-label font-weight-bold" for="customCheck1">اقر بصحة البيانات المدخلة في
                    هذا النموذج وأتحمل المسئولية إن ظهر خلاف ذلك
                </label>
                </div>
            </section>
        </div>
</ng-container>

<div *ngIf="objections?.totalCount > params.maxResultCount" class="pagination-wrapper">
    <ngb-pagination (pageChange)="pageChange($event)" [(page)]="page" [collectionSize]="objections?.totalCount" [maxSize]="1" [pageSize]="params.maxResultCount" [rotate]="true"
    aria-label="Default pagination"></ngb-pagination>
</div>
</div>
