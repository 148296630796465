<ng-template #content>
    <div class="modal-content" cdkDrag cdkDragRootElement=".modal-dialog" cdkDragRootElement="mat-dialog-container"
    [@popupAnimation]="'enter'">
    <div class="modal-header">
        <img class="modal-img" src="{{imgUrl}}" alt="">
        <h5 class="modal-title">{{title}}</h5>
    
    </div>
    <div class="modal-body">
        <ng-content select=[modal-body]></ng-content>
    </div>
    <div class="modal-footer">
      <ng-content select=[modal-footer]></ng-content>
    </div>
    </div>
    </ng-template>