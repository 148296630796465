import { Component, OnInit } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { AppAuthService } from '@spga-shared/providers/auth';
import { Constants } from '@data/common/constant';
import {auditRequestTypeOptions ,supervisorRequestTypeOptions, preliminaryStudiesCommitteeSupervisorFilterOptions, requestPathOptions, statementTypeOptions} from '@proxy/enums';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  reqType = statementTypeOptions;
  // roles = Roles
  currentUserRole!:string
  currentUser: any;
  reqPath = requestPathOptions;
  auditReqType = auditRequestTypeOptions;
  supervisorRequestType = supervisorRequestTypeOptions;
  preliminaryStudiesCommitteeSupervisorFilters = preliminaryStudiesCommitteeSupervisorFilterOptions;
  isDevelopment: boolean = true; // Temp until implement permissions
  isEngineeringOffice: boolean = false; // Temp until implement permissions
  haveTransferPublicRequestRole: boolean = false;
  constructor(private config: ConfigStateService, private authService: AppAuthService) {
  }

  date?: number;

  ngOnInit(): void {
    
    this.isDevelopment = !Constants.isProduction;
    this.findCurrentUserRole();

    this.currentUser = this.authService.currentLoggedInUser$;
    this.haveTransferPublicRequestRole = this.currentUser.roles[0] == 'TransferPublicRequest'    

    this.isEngineeringOffice = this.authService.checkIfIsEngineeringOffice();
    this.date = new Date().getFullYear();
  }

  logout() {
    this.authService.logout();
  }

  findCurrentUserRole(){
    if (this.authService.checkIfUserHavePermission("SPGA.Requests.SaveConsiderationCommittee")){
      this.currentUserRole = "CommitteeSecretary"
    }else{
      this.currentUserRole = this.authService.currentLoggedInUser$.roles[0]
    }
  }
}
