<ng-container *ngIf="!isFormVisible;else formTemplate">
    <ng-content></ng-content>
</ng-container>

<ng-template #formTemplate>
    <form *ngIf="type;else typeNotDefined" [formGroup]="form">
        <div class="row">
            <div class="col-md-6">                    
                <h3 *ngIf="inquiryTitle" class="app-section-title pt-2">{{inquiryTitle}}</h3>
                <div class="form-group">
                    
<div *ngFor="let item of menuDetailsWithOptions">
        <label for="inquiry">{{item.title}}</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'اختيار'"
                    [settings]="dropdownSettings"
                    [data]="item.options"
                    (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)"
                    (onDeSelectAll)="onDeSelectAll()"
                  >
                  </ng-multiselect-dropdown>                  
                </div>
                <div *ngIf="menuDetailsWithOptions&&menuDetailsWithOptions.length">
                        <label for="otherStatement">ملاحظات اخرى</label>
                        <textarea class="form-control" formControlName="other" id="otherStatement" maxlength="250" name="otherStatement" rows="5"></textarea>
                </div>
                <label class="required" for="inquiry" *ngIf="!menuDetailsWithOptions.length">نص الإفادة</label>
                    <textarea class="form-control" formControlName="statement" id="inquiry" maxlength="4000" name="inquiry" rows="5" *ngIf="!menuDetailsWithOptions.length"></textarea>
                </div>
                <ng-container *ngTemplateOutlet="attachments"></ng-container>
                <ng-template #attachments>
                    <h2 class="field__label mb-4">المرفقات</h2>
                    <div *ngIf="attachmentsInputs.length>0;else noAttachments" class="row">
                        <div *ngFor="let attachmentInput of attachmentsInputs" class="col-md-4">
                            <app-custom-file-upload (uploadRes)="handleAttachment(attachmentInput,$event)" [attachmentInput]="attachmentInput.attachment" [attachmentType]="attachmentInput.attachmentType" [desc]="attachmentInput.optional ? 'اختياري':'اجباري'"
                                [showRemove]="true" [hasDate]="false" [title]="attachmentInput.title" [uploadFn]="uploadFn">
                            </app-custom-file-upload>
                        </div>
                    </div>
                    <ng-template #noAttachments>
                        <div class="alert alert-info">لا توجد مرفقات</div>
                    </ng-template>
                    </ng-template>
                    <div *ngIf="type[0]==1||type[0]==11||type[0]==21||type[0]==31||type[0]==41||type[0]==51||type[0]==61;">
                            <h6 class="alert alert-warning font-wight-bold" role="alert">
                                    *سيتم أرسال الإفادة على النحو التالي:
                            </h6>
                           <div class="examp-new">
                                <p>المقدمة :- "عزيزي مقدم الطلب، نود إشعارك بوجود ملاحظات على الطلب الرجاء التحقق من : "
                                </p>
                                    <h6>
                                            نص الإفادة المُختار من قبل العضو.
                                    </h6>
                                    <p>
                                            الخاتمة :- "واستيفاء المطلوب عبر منصة "إحكام" لاستكمال دراسة الطلب."
                                    </p>
                           </div>
                           
                        </div>
                        <div *ngIf="type[0]==3||type[0]==13||type[0]==23||type[0]==33;">
                                <h6 class="alert alert-warning font-wight-bold" role="alert">
                                        *سيتم أرسال الإفادة على النحو التالي:
                                </h6>
                                <div class="examp-new">
                                <p>المقدمة :- "السادة المكتب الهندسي ، نود إشعارك بوجود ملاحظات على الطلب الرجاء التحقق من : "
                                </p>
                                    <h6>
                                            نص الإفادة المُختار من قبل العضو.
                                    </h6>
                                    <p>
                                            الخاتمة :- "واستيفاء المطلوب عبر منصة "إحكام" لاستكمال دراسة الطلب."

                                    </p>
                                    </div>
                            </div>
            </div>            
        </div>
        <div class="col-6 d-flex"> 
        <button [disabled]="isSubmitting || isSave || form?.invalid" class="btn btn-primary" (click)="submitForm()">
      <i *ngIf="isSubmitting" class="fa fa-spinner fa-spin mr-2"></i>
      طلب إفادة
    </button>
        <button [disabled]="isSubmitting || isSave || (form?.invalid && !haveAttachments)" class="btn btn-primary ml-3"
            (click)="onSave()" *ngIf="isToSPGAPublicUser">
            <i *ngIf="isSave" class="fa fa-spinner fa-spin mr-2"></i>
            الحفظ لاحقًا
        </button>
        <button (click)="hideForm()" [disabled]="isSubmitting" class="btn btn-outline-dark ml-auto" type="button">إلغاء</button>
    </div>
    </form>
</ng-template>

<ng-template #typeNotDefined>
    <div class="alert alert-danger mt-4">
        يجب تحديد نوع الإفادة
    </div>
</ng-template>
    