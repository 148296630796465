import { Component, OnInit } from '@angular/core';
import { ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { AdminApplicationLayoutComponent } from './views/admin-application-layout/admin-application-layout.component';
import { AdminEmptyLayoutComponent } from './views/admin-empty-layout/admin-empty-layout.component';
import { AppAuthService } from '@spga-shared/providers/auth/app-auth-service.service';
import { UserIdleService } from '@spga-shared/providers/auth/user-idle.service';
import { environment } from '../environments/environment';
import { ApmService } from '@elastic/apm-rum-angular';
const { init: initApm, createTracer } = require('@elastic/apm-rum/dist/bundles/elastic-apm-opentracing.umd.js')
const opentracing = require('opentracing');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUser!: any
  constructor(private replaceableComponents: ReplaceableComponentsService,
    private authService: AppAuthService,
    private userIdleService: UserIdleService,
    private apmService: ApmService,
  ) {
    this.replaceableComponents.add({
      component: AdminApplicationLayoutComponent,
      key: eThemeBasicComponents.ApplicationLayout
    });
    this.replaceableComponents.add({
      component: AdminEmptyLayoutComponent,
      key: eThemeBasicComponents.EmptyLayout
    });
    this.registerApmService();
  }
  ngOnInit(): void {
    if (this.authService.checkIfLoggedIn()) {
      this.userIdleService.startInializeIdelTime(environment.adminSessionTimeInMin);      
    }
  }

  registerApmService() {
    this.currentUser = this.authService.currentLoggedInUser$;

    const apm = this.apmService.init({
      serviceName: environment.rumConfig?.serviceName,
      serverUrl: environment.rumConfig?.serverUrl,
    });

    if (this.currentUser?.id) {
      apm.setUserContext({
        'username': this.currentUser?.userName,
        'id': this.currentUser?.id,
        'email': this.currentUser?.email,
      })
    }

    const elasticTracer = createTracer(apm)
    opentracing.initGlobalTracer(elasticTracer);
  }

}
