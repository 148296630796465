import { NgModule } from '@angular/core';
import { CommonSharedModule } from '@spga-shared/common-shared.module';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AdminApplicationLayoutComponent } from '../views/admin-application-layout/admin-application-layout.component';
import { AdminEmptyLayoutComponent } from '../views/admin-empty-layout/admin-empty-layout.component';
import { RequestInquiryComponent } from '../request-preview/request-inquiry/request-inquiry.component';
import { RequestDetailsComponent } from '../request-preview/request-details/request-details.component';
import { VoteFormComponent } from './components/vote-form/vote-form.component';
import { ObjectionDetailsComponent } from '../objection-preview/objection-details/objection-details.component';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import { PopupComponent } from './components/popup/popup.component';

const declarations = [
  HeaderComponent,
  SidebarComponent,
  UserProfileComponent,
  AdminApplicationLayoutComponent,
  AdminEmptyLayoutComponent,
  RequestInquiryComponent,
  RequestDetailsComponent,
  ObjectionDetailsComponent,
  VoteFormComponent,
  PopupComponent
];

const imports = [CommonSharedModule ,   MatSelectModule,
  MatFormFieldModule,
  MatTooltipModule];

@NgModule({
  declarations: [declarations],
  imports: [imports],
  exports: [declarations, imports]
})
export class SharedModule {
  // tslint:disable-next-line:typedef
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [CommonSharedModule.forRoot().providers]
    };
  }
}
