<app-request-inquiry #inquiryComponent [requestId]="requestId" [type]="statementType" inquiryTitle="إعادة الطلب للمستفيد">
    <ng-container *ngIf="canCreateStatement && statementResponse && canViewStatmentHistory  && !canViewStatementReplyHistoryForAllDept">
        <app-card *ngFor="let statementResponse of statementResponse.items" [statment]="statementResponse"></app-card>
        <div *ngIf="statementResponse?.totalCount > params.maxResultCount" class="pagination-wrapper">
            <ngb-pagination (pageChange)="pageChange($event)" [(page)]="page" [collectionSize]="statementResponse?.totalCount" [maxSize]="3" [pageSize]="params.maxResultCount" [rotate]="true"
            aria-label="Default pagination"></ngb-pagination>
        </div>
    </ng-container>

    <ng-container *ngIf="canViewStatementReplyHistoryForAllDept && haveStatementReplyHistoryForAllDept">
        <h2 class="fields-title mb-3">الإفادات المرسلة</h2>
        <div class="accordion-container">
    <ngb-accordion [closeOthers]="true" activeIds="static-1">
        <ng-container *ngFor="let statementForAllDeptResponse of statementReplyHistoryForAllDeptResponse">
        <ngb-panel *ngIf="statementForAllDeptResponse?.statementReplyHistoryPerDeptResponses?.length>0">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="accordion-button d-flex custom-header justify-content-between" [class.collapsed]="!opened">
                <button ngbPanelToggle class="btn btn-link p-0 w-100 text-left departement-name">{{statementForAllDeptResponse?.deptName}}</button>
                <button ngbPanelToggle class="col-1 button-nostyle toggle-icon"></button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
           <app-card *ngFor="let statementReplyHistoryPerDeptResponse of statementForAllDeptResponse.statementReplyHistoryPerDeptResponses" [statment]="statementReplyHistoryPerDeptResponse"></app-card> 
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
    </div>
  </ng-container>

    <ng-container *ngIf="request">
      <div *ngIf="request" #beneficiaryDetails>
        <h2 *ngIf="!isEmirateRepresentative" class="fields-title">بيانات المستفيد</h2>
        <div class="row">
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryIdentificationNumber">
            <div class="field">
              <h2 [class]="'field__label'">رقم الهوية الوطنية</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryIdentificationNumber}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryName">
            <div class="field">
              <h2 [class]="'field__label'">اسم المستفيد</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryName}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequesBeneficiaryBirthdate">
            <div class="field">
              <h2 [class]="'field__label'">تاريخ الميلاد</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryBirthdate| customDate}}
              </p>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryEmail">
            <div class="field">
              <h2 [class]="'field__label'">البريد الإلكتروني</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryEmail}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryMobileNumber">
            <div class="field">
              <h2 [class]="'field__label'">رقم الجوال</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficiaryMobileNumber}}
              </p>
            </div>
          </div>
        </div>

        <hr>

        <h2 class="fields-title">بيانات طالب التملك او التعديل</h2>
        <div class="row">
          <div class="col-md-4" *ngIf="canAccessRequestOwnerName">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.name" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.name != request.owner.name) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.name != request.owner.name) ?   'field__label icon icon-edit': 'field__label'">الاسم بالكامل</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.owner.name}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequestOwnerNationality">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.nationalityText" placement="top" tooltipClass="abadi" [triggers]="( request.originalRequest &&  request.originalRequest?.owner.nationalityText != request.owner.nationalityText) ? 'hover' : ''" [class]="( request.originalRequest &&  request.originalRequest?.owner.nationalityText != request.owner.nationalityText) ?   'field__label icon icon-edit': 'field__label'">الجنسية</h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.nationalityText}}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" *ngIf="canAccessRequestOwnerIdentificationType">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.identificationTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.identificationTypeText != request.owner.identificationTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.identificationTypeText != request.owner.identificationTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع الهوية </h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.identificationTypeText}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerIdentificationNumber">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.identificationNumber" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest &&  request.originalRequest?.owner.identificationNumber != request.owner.identificationNumber) ? 'hover' : ''" [class]="(request.originalRequest &&  request.originalRequest?.owner.identificationNumber != request.owner.identificationNumber) ?   'field__label icon icon-edit': 'field__label'">رقمها</h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.identificationNumber}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerIssueDate">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.issueDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest &&  request.originalRequest?.owner.issueDate != request.owner.issueDate) ? 'hover' : ''" [class]="(request.originalRequest &&  request.originalRequest?.owner.issueDate != request.owner.issueDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الاصدار</h2>
              <p class="field__data">
                <i class="icon icon-calendar"></i> {{request.owner.issueDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerExpiryDate">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.expiryDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest &&  request.originalRequest?.owner.expiryDate != request.owner.expiryDate) ? 'hover' : ''" [class]="(request.originalRequest &&  request.originalRequest?.owner.expiryDate != request.owner.expiryDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الانتهاء</h2>
              <p class="field__data">
                <i class="icon icon-calendar"></i> {{request.owner.expiryDate | customDate}}
              </p>
            </div>
          </div>
        </div>
        <hr>

        <h2 class="fields-title">بيانات ممثل صاحب الطلب</h2>
        <div class="row">
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeName">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.name" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.name != request.owner.ownerRepresentative.name) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.name != request.owner.ownerRepresentative.name) ?   'field__label icon icon-edit': 'field__label'">الاسم بالكامل</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.owner.ownerRepresentative.name}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeIdentificationNumber">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.identificationNumber" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.identificationNumber != request.owner.ownerRepresentative.identificationNumber) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.identificationNumber != request.owner.ownerRepresentative.identificationNumber) ?   'field__label icon icon-edit': 'field__label'">رقم الهوية</h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.ownerRepresentative.identificationNumber}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeExpiryDate">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.expiryDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.expiryDate != request.owner.ownerRepresentative.expiryDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.expiryDate != request.owner.ownerRepresentative.expiryDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الانتهاء</h2>
              <p class="field__data">
                <i class="icon icon-calendar"></i> {{request.owner.ownerRepresentative.expiryDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeTypeText">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.ownerRepresentativeTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.ownerRepresentativeTypeText != request.owner.ownerRepresentative.ownerRepresentativeTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.ownerRepresentativeTypeText != request.owner.ownerRepresentative.ownerRepresentativeTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع ممثل صاحب الطلب</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.ownerRepresentativeTypeText}}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="request?.owner.ownerRepresentative.ownerRepresentativeType==2" class="row">
          <div class="col-md-3">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.agencyNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyNo != request.owner.ownerRepresentative.agencyNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyNo != request.owner.ownerRepresentative.agencyNo) ?   'field__label icon icon-edit': 'field__label'">رقم الوكالة</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.agencyNo}}
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.agencyDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyDate != request.owner.ownerRepresentative.agencyDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyDate != request.owner.ownerRepresentative.agencyDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الوكالة</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.agencyDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.agencySource" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencySource != request.owner.ownerRepresentative.agencySource) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencySource != request.owner.ownerRepresentative.agencySource) ?   'field__label icon icon-edit': 'field__label'">مصدر الوكالة</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.agencySource}}
              </p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="canCreateStatement">
          <hr>

          <h2 class="fields-title">بيانات عنوان ممثل صاحب الطلب</h2>
          <div class="row">
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeRegion">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.regionName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.regionName != request.owner.ownerRepresentative.address.regionName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.regionName != request.owner.ownerRepresentative.address.regionName) ?   'field__label icon icon-edit': 'field__label'">المنطقة</h2>
                <p class="field__data">
                  <i class="icon icon-globe"></i> {{request.owner.ownerRepresentative.address.regionName}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeCity">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.cityName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.cityName != request.owner.ownerRepresentative.address.cityName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.cityName != request.owner.ownerRepresentative.address.cityName) ?   'field__label icon icon-edit': 'field__label'">المدينة</h2>
                <p class="field__data">
                  <i class="icon icon-globe"></i> {{request.owner.ownerRepresentative.address.cityName}}
                </p>
              </div>
            </div>
            <div class="col-md-6" *ngIf="canAccessRequestOwnerRepresentativeStreet">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.street" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.street != request.owner.ownerRepresentative.address.street) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.street != request.owner.ownerRepresentative.address.street) ?   'field__label icon icon-edit': 'field__label'">الشارع</h2>
                <p class="field__data">
                  <i class="icon icon-globe"></i> {{request.owner.ownerRepresentative.address.street}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeBuildingNo">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.buildingNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.buildingNo != request.owner.ownerRepresentative.address.buildingNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.buildingNo != request.owner.ownerRepresentative.address.buildingNo) ?   'field__label icon icon-edit': 'field__label'">رقم المبنى</h2>
                <p class="field__data">
                  {{request.owner.ownerRepresentative.address.buildingNo}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativePostalCode">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.postalCode" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.postalCode != request.owner.ownerRepresentative.address.postalCode) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.postalCode != request.owner.ownerRepresentative.address.postalCode) ?   'field__label icon icon-edit': 'field__label'">الرمز البريدي</h2>
                <p class="field__data">
                  {{request.owner.ownerRepresentative.address.postalCode}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeUnitNo">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.unitNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.unitNo != request.owner.ownerRepresentative.address.unitNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.unitNo != request.owner.ownerRepresentative.address.unitNo) ?   'field__label icon icon-edit': 'field__label'">رقم الوحدة</h2>
                <p class="field__data">
                  {{request.owner.ownerRepresentative.address.unitNo}}
                </p>
              </div>
            </div>
          </div>
        </ng-container>

        <hr *ngIf="request.requestType">

        <h2 class="fields-title" *ngIf="request.requestType">بيانات الطلب والعقار</h2>
        <ng-container *ngIf="request.requestType">
          <div class="row">
            <div class="col-md-3" *ngIf="canAccessRequestNumber">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestId" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.requestId != request.requestId) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.requestId != request.requestId) ?   'field__label icon icon-edit': 'field__label'">رقم الطلب</h2>
                <p class="field__data">
                  {{request.requestId}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestCreationDate">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestCreatedDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.requestCreatedDate != request.requestCreatedDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.requestCreatedDate != request.requestCreatedDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الطلب</h2>
                <p class="field__data">
                  {{request.requestCreatedDate | customDate}}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3" *ngIf="canAccessRequestType">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.requestTypeText != request.requestTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.requestTypeText != request.requestTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع الطلب</h2>
                <p class="field__data">
                  {{request.requestTypeText}}
                </p>
              </div>
            </div>
            <div *ngIf="request?.requestType==3" class="col-md-3">
              <div class="field">
                <h2 class="field__label"> نوع الاستكمال </h2>
                <p class="field__data">
                  {{request?.completeProceduresTypesText}}
                </p>
              </div>
            </div>

            <div *ngIf="request?.requestType===3 && request?.completeProceduresTypes?.includes(13)&&request.requestAgencyTypesText?.length>0" class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestAgencyTypesText" placement="top" tooltipClass="abadi" [triggers]="isRequestAgencyTypesTextChanged() ? 'hover' : ''" [class]="isRequestAgencyTypesTextChanged() ?   'field__label icon icon-edit': 'field__label'"> الجهات الحكومية </h2>
                <p class="field__data">
                  {{request.requestAgencyTypesText}}
                </p>
              </div>
            </div>
            <div *ngIf="request?.requestType==2 && canAccessRequestFormalModificationText" class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.formalModificationText" placement="top" tooltipClass="abadi" [triggers]="isFormalModificationTextChanged() ? 'hover' : ''" [class]="isFormalModificationTextChanged() ?   'field__label icon icon-edit': 'field__label'"> نوع التعديل الشكلي </h2>
                <p class="field__data">
                  {{request.formalModificationText}}
                </p>
              </div>
            </div>

          </div>
          <ng-container *ngIf="request?.landNumbers?.length>0 && request?.requestType!==1">
            <h2 class="field__label"> رقم / اسم القطعة </h2>
            <div class="row mb-4 align-items-center">
              <div class="col-3" *ngFor="let land of request?.landNumbers.slice(0,3)">
                <div class="landCard">
                  <span class="landCard-text">{{land.number?land.number:land}}</span>
                </div>
              </div>
              <p class="col-3">
                <button type="button" class="btn btn-sm btn-primary request-btn" (click)="isLandsModalOpen = true">
                  استعراض عدد
                  القطع ( {{request?.landNumbers?.length || 0}} )
                </button>
              </p>
              <abp-modal [(visible)]="isLandsModalOpen" [options]="{size: 'lg',modalDialogClass:'lands-modal'}">
                <ng-template #abpHeader>
                  <h5>عدد القطع ( {{request?.landNumbers?.length || 0}} )</h5>
                </ng-template>
                <ng-template #abpBody>
                  <div class="row land-wrapper justify-content-center">
                    <div class="col-8" *ngFor="let land of request?.landNumbers">
                      <div class="landCard">
                        <span class="landCard-text">{{land.number?land.number:land}}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </abp-modal>
            </div>
          </ng-container>

          <!-- *ngIf="request?.requestType==2" -->
          <div class="row" *ngIf="request?.instrumentNo && request?.requestType!==1">
            <div class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.instrumentNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.instrumentNo != request.instrumentNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.instrumentNo != request.instrumentNo) ?   'field__label icon icon-edit': 'field__label'">رقم الصك</h2>
                <p class="field__data">
                  {{request.instrumentNo}}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.instrumentDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.instrumentDate != request.instrumentDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.instrumentDate != request.instrumentDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الصك</h2>
                <p class="field__data">
                  {{request.instrumentDate | customDate}}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.instrumentSource" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.instrumentSource != request.instrumentSource) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.instrumentSource != request.instrumentSource) ?   'field__label icon icon-edit': 'field__label'">مصدر الصك</h2>
                <p class="field__data">
                  {{request.instrumentSource}}
                </p>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateTypeText">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.realEstateTypeText || request.originalRequest?.realEstate.otherRealEstateType" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && (request.originalRequest?.realEstate.realEstateTypeText != request.realEstate.realEstateTypeText || request.originalRequest?.realEstate.otherRealEstateType != request.realEstate.otherRealEstateType)) ? 'hover' : ''" [class]="(request.originalRequest && (request.originalRequest?.realEstate.realEstateTypeText != request.realEstate.realEstateTypeText || request.originalRequest?.realEstate.otherRealEstateType != request.realEstate.otherRealEstateType)) ?   'field__label icon icon-edit': 'field__label'">وصف العقار</h2>
                <p class="field__data">
                  {{request.realEstate.realEstateTypeText || request.realEstate.otherRealEstateType}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateRegionName">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.regionName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.regionName != request.realEstate.regionName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.regionName != request.realEstate.regionName) ?   'field__label icon icon-edit': 'field__label'">المنطقة</h2>
                <p class="field__data">
                  {{request.realEstate.regionName}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateCityName">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.cityName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.cityName != request.realEstate.cityName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.cityName != request.realEstate.cityName) ?   'field__label icon icon-edit': 'field__label'">المدينة</h2>
                <p class="field__data">
                  {{request.realEstate.cityName}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateAreaMeters">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.areaMeters" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.areaMeters != request.realEstate.areaMeters) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.areaMeters != request.realEstate.areaMeters) ?   'field__label icon icon-edit': 'field__label'">المساحه</h2>
                <p class="field__data">
                  {{request.realEstate.areaMeters}}
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="canCreateStatement || isEmirateRepresentative" class="row">
            <div class="col-md-3" *ngIf="canAccessRequestDocumentTypeText">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.documentTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.documentTypeText != request.realEstate.documentTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.documentTypeText != request.realEstate.documentTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع الوثيقة</h2>
                <p class="field__data">
                  {{request.realEstate.documentTypeText}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestDocumentDate">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.documentDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.documentDate != request.realEstate.documentDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.documentDate != request.realEstate.documentDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الوثيقة</h2>
                <p class="field__data">
                  {{request.realEstate.documentDate | customDate}}
                </p>
              </div>
            </div>
            <div class="col-md-12">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.isThereRequestForJudicialPossession ? 'نعم يوجد' : 'لا يوجد'" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.isThereRequestForJudicialPossession != request.isThereRequestForJudicialPossession) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.isThereRequestForJudicialPossession != request.isThereRequestForJudicialPossession) ?   'field__label icon icon-edit': 'field__label'">هل يوجد لديك طلب استحكام منظور قضائيا على نفس الطلب؟</h2>
                <p class="field__data">
                  {{request.isThereRequestForJudicialPossession ? 'نعم' : 'لا'}} يوجد
                </p>
              </div>
            </div>
            <ng-container *ngIf="request.isThereRequestForJudicialPossession && canAccessRequestCourtText">
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.courtText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.courtText != request.courtText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.courtText != request.courtText) ?   'field__label icon icon-edit': 'field__label'">اسم المحكمة</h2>
                  <p class="field__data">
                    {{request.courtText}}
                  </p>
                </div>
              </div>
              <div class="col-md-3" *ngIf="canAccessRequestCaseNo">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.caseNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.caseNo != request.caseNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.caseNo != request.caseNo) ?   'field__label icon icon-edit': 'field__label'">رقم الدعوى</h2>
                  <p class="field__data">
                    {{request.caseNo}}
                  </p>
                </div>
              </div>
              <div class="col-md-3" *ngIf="canAccessRequestCaseDate">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.caseDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.caseDate != request.caseDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.caseDate != request.caseDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الدعوى</h2>
                  <p class="field__data">
                    {{request.caseDate | customDate}}
                  </p>
                </div>
              </div>
              <div class="col-md-3" *ngIf="canAccessRequestCaseStatusText">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.caseStatusText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.caseStatusText != request.caseStatusText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.caseStatusText != request.caseStatusText) ?   'field__label icon icon-edit': 'field__label'">حالة الدعوى</h2>
                  <p class="field__data">
                    {{request.caseStatusText}}
                  </p>
                </div>
              </div>

            </ng-container>

          </div>
          <div *ngIf="request?.realEstate.isRealestateRevived !== null" class="row">
            <div class="col-md-12">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.isRealestateRevived ? 'نعم' : 'لا'" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.isRealestateRevived != request.realEstate.isRealestateRevived) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.isRealestateRevived != request.realEstate.isRealestateRevived) ?   'field__label icon icon-edit': 'field__label'">هل العقار تم إحياؤه؟</h2>
                <p class="field__data">
                  {{request.realEstate.isRealestateRevived ? 'نعم' : 'لا'}}
                </p>
              </div>
            </div>
            <ng-container *ngIf="request.realEstate.isRealestateRevived">
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.realEstate.revivalTypeText" placement="top" tooltipClass="abadi" [triggers]="isRevivalTypeTextChanged() ? 'hover' : ''" [class]="isRevivalTypeTextChanged() ?   'field__label icon icon-edit': 'field__label'">نوع الإحياء</h2>
                  <p class="field__data">
                    {{request.realEstate.revivalTypeText}}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.realEstate.revivalEvidenceText" placement="top" tooltipClass="abadi" [triggers]="isRevivalEvidenceTextChanged() ? 'hover' : ''" [class]="isRevivalEvidenceTextChanged() ?   'field__label icon icon-edit': 'field__label'">أسانيد الإحياء</h2>
                  <p class="field__data">
                    {{request.realEstate.revivalEvidenceText}}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.realEstate.revivalDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.revivalDate != request.realEstate.revivalDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.revivalDate != request.realEstate.revivalDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الاحياء</h2>
                  <p class="field__data">
                    {{'::Enum:RevivalDate:'+request.realEstate.revivalDate|abpLocalization}}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-template #map>
            <div class="location mb-4" *ngIf="canAccessRequestRealEstateLocation">
              <app-static-map [location]="{lat:request.originalRequest?.realEstate.cordinates[0].latitude , lng:request.originalRequest?.realEstate.cordinates[0].longitude}" #map></app-static-map>
            </div>
          </ng-template>
          <div class="location mb-4" *ngIf="canAccessRequestRealEstateLocation">
            <h2 [ngbTooltip]="map" [closeDelay]="100000000000000000" [autoClose]="'outside'" placement="top" tooltipClass="abadi container" [triggers]="(request.originalRequest && (request.originalRequest?.realEstate.cordinates[0].latitude != request.realEstate.cordinates[0].latitude || request.originalRequest?.realEstate.cordinates[0].longitude != request.realEstate.cordinates[0].longitude)) ? 'hover' : ''" [class]="(request.originalRequest && (request.originalRequest?.realEstate.cordinates[0].latitude != request.realEstate.cordinates[0].latitude || request.originalRequest?.realEstate.cordinates[0].longitude != request.realEstate.cordinates[0].longitude)) ?   'field__label icon icon-edit': 'field__label mb-4'">موقع العقار</h2>
            <app-static-map [location]="mapLocation"></app-static-map>
          </div>
        </ng-container>
      </div>
      <hr>

      <ng-template #attachment>


        <div class="attachments mt-4">
          <div *ngIf="request.originalRequest.realEstateAttachment?.length;else noAttachments" class="row">
            <div *ngFor="let attachment of request.originalRequest.realEstateAttachment" class="col-md-3">
              <app-custom-file-upload [attachmentInput]="attachment" [downloadFn]="downloadAttachmentFn" [hasDate]="false"></app-custom-file-upload>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="attachments mt-4">

        <h2 *ngIf="!isEmirateRepresentative" [ngbTooltip]="attachment" [closeDelay]="100000000000000000" [autoClose]="'outside'" placement="top" tooltipClass="abadi container" [triggers]="isAttachmentChanged() ? 'hover' : ''" [class]="isAttachmentChanged() ? 'app-section-title icon icon-edit mb-4': 'app-section-title mb-4'">مرفقات العقار</h2>

        <div *ngIf="request.realEstateAttachment?.length;else noAttachments" class="row">
          <div *ngFor="let attachment of request.realEstateAttachment" class="col-md-3">
            <app-custom-file-upload [attachmentInput]="attachment" [downloadFn]="downloadAttachmentFn" [hasDate]="false"></app-custom-file-upload>
          </div>
        </div>
        <ng-template #noAttachments>
          <div *ngIf="!isEmirateRepresentative" class="alert alert-info">لا توجد مرفقات علي العقار</div>
        </ng-template>

      
        <button (click)="downloadAndMergeToPdf()"
                class="btn btn-primary"
                 *ngIf="request.realEstateAttachment?.length && !isEmirateRepresentative"
                [disabled]="isLoading">
          <span *ngIf="!isLoading">تنزيل المرفقات</span>
          <span *ngIf="isLoading">جاري تفعيل تنزيل المرفقات...</span>
        </button>
      </div>

      <hr *ngIf="!isEmirateRepresentative">
      <div *ngIf="request.requestBill && !isPreWithApprovedRequest">
        <h2 *ngIf="!isEmirateRepresentative" class="fields-title">بيانات الفاتورة</h2>
        <div class="row">
          <div class="col-md-3" *ngIf="canAccessSadadNumber">
            <div class="field">
              <h2 class="field__label">رقم السداد</h2>
              <p class="field__data">
                {{request.requestBill?.sadadNumber}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessSadadCreationDate">
            <div class="field">
              <h2 class="field__label">تاريخ إنشاء الفاتورة</h2>
              <p class="field__data">
                {{request.requestBill?.sadadCreationDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessSadadPayingDate">
            <div class="field">
              <h2 class="field__label">تاريخ السداد</h2>
              <p class="field__data">
                {{request.requestBill?.sadadPayingDate | customDate}}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" *ngIf="canAccessBillExpirationDate">
            <div class="field">
              <h2 class="field__label">تاريخ إنتهاء الفاتورة</h2>
              <p class="field__data">
                {{request.requestBill?.billExpirationDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessBillAmount">
            <div class="field">
              <h2 class="field__label">مبلغ الفاتورة</h2>
              <p class="field__data">
                {{request.requestBill?.billAmount}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessBillVat">
            <div class="field">
              <h2 class="field__label">قيمة الضريبة المضافة</h2>
              <p class="field__data">
                {{request.requestBill?.billVat}}
              </p>
            </div>
          </div>
        </div>

      </div>


      <hr *ngIf="isAuditCommitteeSecretary && request.ads">
      <div *ngIf="isAuditCommitteeSecretary && request.ads">
        <h2 *ngIf="isAuditCommitteeSecretary" class="fields-title">تفاصيل الإعلان</h2>
        <div class="row">
          <div class="col-md-3">
            <div class="field">
              <h2 class="field__label">تاريخ بداية الاعلان</h2>
              <p class="field__data">
                {{request.ads?.publishingDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="field">
              <h2 class="field__label">تاريخ نهاية الاعلان</h2>
              <p class="field__data">
                {{request.ads?.adsEndDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="field">
              <h2 class="field__label">المدة المتبقية</h2>
              <p class="field__data">
                {{request.ads?.remainingDays}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="actions mt-4">
        <button (click)="acceptRequest()" *abpPermission="'SPGA.Requests.Accept_Request'" [disabled]="isSubmitting" class="btn btn-primary mr-2">
          قبول الطلب
          <i *ngIf="isSubmitting" class="fa fa-spinner fa-spin mr-1"></i>
        </button>

        <!-- <div *ngIf="!isSecretary"> -->
        <ng-container *ngIf="(request.requestStatus != requestStatus.WaitingForPaid && request.requestStatus != requestStatus.Outside && request.requestStatus != requestStatus.UnderReviewRequestsStudyingMember &&!isEngOffice &&!isAuditCommitteeSecretary) || isRequestsStudyingMemberRequests ">
          <button (click)="inquiryComponent.showForm()" *abpPermission="'SPGA.Requests.CreateStatement'" [disabled]="isSubmitting" class="btn btn-primary">
            طلب افادة مستفيد
          </button>
        </ng-container>
        <!-- </div> -->

      </div>
      <p *ngIf="request.originalRequest" class="mt-4 icon icon-edit">تم تعديل هذا الحقل (للاطلاع على البيانات ماقبل التعديل مرر الماوس فوق العلامة)</p>

      <!-- New shadow element -->
      <div *ngIf="request" #shadowBeneficiaryDetails class="shadow-container">
        <h2 *ngIf="!isEmirateRepresentative" class="fields-title">بيانات المستفيد</h2>
        <div class="row">
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryIdentificationNumber">
            <div class="field">
              <h2 [class]="'field__label'">رقم الهوية الوطنية</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryIdentificationNumber}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryName">
            <div class="field">
              <h2 [class]="'field__label'">اسم المستفيد</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryName}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequesBeneficiaryBirthdate">
            <div class="field">
              <h2 [class]="'field__label'">تاريخ الميلاد</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryBirthdate| customDate}}
              </p>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryEmail">
            <div class="field">
              <h2 [class]="'field__label'">البريد الإلكتروني</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficaryEmail}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequestBeneficiaryMobileNumber">
            <div class="field">
              <h2 [class]="'field__label'">رقم الجوال</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.beneficiaryMobileNumber}}
              </p>
            </div>
          </div>
        </div>

        <hr>

        <h2 class="fields-title">بيانات طالب التملك او التعديل</h2>
        <div class="row">
          <div class="col-md-4" *ngIf="canAccessRequestOwnerName">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.name" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.name != request.owner.name) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.name != request.owner.name) ?   'field__label icon icon-edit': 'field__label'">الاسم بالكامل</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.owner.name}}
              </p>
            </div>
          </div>
          <div class="col-md-4" *ngIf="canAccessRequestOwnerNationality">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.nationalityText" placement="top" tooltipClass="abadi" [triggers]="( request.originalRequest &&  request.originalRequest?.owner.nationalityText != request.owner.nationalityText) ? 'hover' : ''" [class]="( request.originalRequest &&  request.originalRequest?.owner.nationalityText != request.owner.nationalityText) ?   'field__label icon icon-edit': 'field__label'">الجنسية</h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.nationalityText}}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" *ngIf="canAccessRequestOwnerIdentificationType">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.identificationTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.identificationTypeText != request.owner.identificationTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.identificationTypeText != request.owner.identificationTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع الهوية </h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.identificationTypeText}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerIdentificationNumber">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.identificationNumber" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest &&  request.originalRequest?.owner.identificationNumber != request.owner.identificationNumber) ? 'hover' : ''" [class]="(request.originalRequest &&  request.originalRequest?.owner.identificationNumber != request.owner.identificationNumber) ?   'field__label icon icon-edit': 'field__label'">رقمها</h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.identificationNumber}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerIssueDate">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.issueDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest &&  request.originalRequest?.owner.issueDate != request.owner.issueDate) ? 'hover' : ''" [class]="(request.originalRequest &&  request.originalRequest?.owner.issueDate != request.owner.issueDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الاصدار</h2>
              <p class="field__data">
                <i class="icon icon-calendar"></i> {{request.owner.issueDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerExpiryDate">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.expiryDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest &&  request.originalRequest?.owner.expiryDate != request.owner.expiryDate) ? 'hover' : ''" [class]="(request.originalRequest &&  request.originalRequest?.owner.expiryDate != request.owner.expiryDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الانتهاء</h2>
              <p class="field__data">
                <i class="icon icon-calendar"></i> {{request.owner.expiryDate | customDate}}
              </p>
            </div>
          </div>
        </div>
        <hr>

        <h2 class="fields-title">بيانات ممثل صاحب الطلب</h2>
        <div class="row">
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeName">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.name" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.name != request.owner.ownerRepresentative.name) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.name != request.owner.ownerRepresentative.name) ?   'field__label icon icon-edit': 'field__label'">الاسم بالكامل</h2>
              <p class="field__data">
                <i class="icon icon-user"></i> {{request.owner.ownerRepresentative.name}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeIdentificationNumber">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.identificationNumber" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.identificationNumber != request.owner.ownerRepresentative.identificationNumber) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.identificationNumber != request.owner.ownerRepresentative.identificationNumber) ?   'field__label icon icon-edit': 'field__label'">رقم الهوية</h2>
              <p class="field__data">
                <i class="icon icon-id-card-long"></i> {{request.owner.ownerRepresentative.identificationNumber}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeExpiryDate">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.expiryDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.expiryDate != request.owner.ownerRepresentative.expiryDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.expiryDate != request.owner.ownerRepresentative.expiryDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الانتهاء</h2>
              <p class="field__data">
                <i class="icon icon-calendar"></i> {{request.owner.ownerRepresentative.expiryDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeTypeText">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.ownerRepresentativeTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.ownerRepresentativeTypeText != request.owner.ownerRepresentative.ownerRepresentativeTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.ownerRepresentativeTypeText != request.owner.ownerRepresentative.ownerRepresentativeTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع ممثل صاحب الطلب</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.ownerRepresentativeTypeText}}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="request?.owner.ownerRepresentative.ownerRepresentativeType==2" class="row">
          <div class="col-md-3">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.agencyNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyNo != request.owner.ownerRepresentative.agencyNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyNo != request.owner.ownerRepresentative.agencyNo) ?   'field__label icon icon-edit': 'field__label'">رقم الوكالة</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.agencyNo}}
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.agencyDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyDate != request.owner.ownerRepresentative.agencyDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencyDate != request.owner.ownerRepresentative.agencyDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الوكالة</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.agencyDate | customDate}}
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="field">
              <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.agencySource" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencySource != request.owner.ownerRepresentative.agencySource) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.agencySource != request.owner.ownerRepresentative.agencySource) ?   'field__label icon icon-edit': 'field__label'">مصدر الوكالة</h2>
              <p class="field__data">
                {{request.owner.ownerRepresentative.agencySource}}
              </p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="canCreateStatement">
       
          <div class="row">
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeRegion">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.regionName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.regionName != request.owner.ownerRepresentative.address.regionName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.regionName != request.owner.ownerRepresentative.address.regionName) ?   'field__label icon icon-edit': 'field__label'">المنطقة</h2>
                <p class="field__data">
                  <i class="icon icon-globe"></i> {{request.owner.ownerRepresentative.address.regionName}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeCity">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.cityName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.cityName != request.owner.ownerRepresentative.address.cityName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.cityName != request.owner.ownerRepresentative.address.cityName) ?   'field__label icon icon-edit': 'field__label'">المدينة</h2>
                <p class="field__data">
                  <i class="icon icon-globe"></i> {{request.owner.ownerRepresentative.address.cityName}}
                </p>
              </div>
            </div>
            <div class="col-md-6" *ngIf="canAccessRequestOwnerRepresentativeStreet">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.street" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.street != request.owner.ownerRepresentative.address.street) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.street != request.owner.ownerRepresentative.address.street) ?   'field__label icon icon-edit': 'field__label'">الشارع</h2>
                <p class="field__data">
                  <i class="icon icon-globe"></i> {{request.owner.ownerRepresentative.address.street}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeBuildingNo">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.buildingNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.buildingNo != request.owner.ownerRepresentative.address.buildingNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.buildingNo != request.owner.ownerRepresentative.address.buildingNo) ?   'field__label icon icon-edit': 'field__label'">رقم المبنى</h2>
                <p class="field__data">
                  {{request.owner.ownerRepresentative.address.buildingNo}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativePostalCode">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.postalCode" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.postalCode != request.owner.ownerRepresentative.address.postalCode) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.postalCode != request.owner.ownerRepresentative.address.postalCode) ?   'field__label icon icon-edit': 'field__label'">الرمز البريدي</h2>
                <p class="field__data">
                  {{request.owner.ownerRepresentative.address.postalCode}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestOwnerRepresentativeUnitNo">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.owner.ownerRepresentative.address.unitNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.unitNo != request.owner.ownerRepresentative.address.unitNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.owner.ownerRepresentative.address.unitNo != request.owner.ownerRepresentative.address.unitNo) ?   'field__label icon icon-edit': 'field__label'">رقم الوحدة</h2>
                <p class="field__data">
                  {{request.owner.ownerRepresentative.address.unitNo}}
                </p>
              </div>
            </div>
          </div>
        </ng-container>

        <hr *ngIf="request.requestType">

        <h2 class="fields-title" *ngIf="request.requestType">بيانات الطلب والعقار</h2>
        <ng-container *ngIf="request.requestType">
          <div class="row">

            <div class="col-md-3" *ngIf="canAccessRequestNumber">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestId" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.requestId != request.requestId) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.requestId != request.requestId) ?   'field__label icon icon-edit': 'field__label'">رقم الطلب</h2>
                <p class="field__data">
                  {{request.requestId}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestCreationDate">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestCreatedDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.requestCreatedDate != request.requestCreatedDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.requestCreatedDate != request.requestCreatedDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الطلب</h2>
                <p class="field__data">
                  {{request.requestCreatedDate | customDate}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestType">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.requestTypeText != request.requestTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.requestTypeText != request.requestTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع الطلب</h2>
                <p class="field__data">
                  {{request.requestTypeText}}
                </p>
              </div>
            </div>
            <div *ngIf="request?.requestType==3" class="col-md-3">
              <div class="field">
                <h2 class="field__label"> نوع الاستكمال </h2>
                <p class="field__data">
                  {{request?.completeProceduresTypesText}}
                </p>
              </div>
            </div>

            <div *ngIf="request?.requestType===3 && request?.completeProceduresTypes?.includes(13)&&request.requestAgencyTypesText?.length>0" class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.requestAgencyTypesText" placement="top" tooltipClass="abadi" [triggers]="isRequestAgencyTypesTextChanged() ? 'hover' : ''" [class]="isRequestAgencyTypesTextChanged() ?   'field__label icon icon-edit': 'field__label'"> الجهات الحكومية </h2>
                <p class="field__data">
                  {{request.requestAgencyTypesText}}
                </p>
              </div>
            </div>
            <div *ngIf="request?.requestType==2 && canAccessRequestFormalModificationText" class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.formalModificationText" placement="top" tooltipClass="abadi" [triggers]="isFormalModificationTextChanged() ? 'hover' : ''" [class]="isFormalModificationTextChanged() ?   'field__label icon icon-edit': 'field__label'"> نوع التعديل الشكلي </h2>
                <p class="field__data">
                  {{request.formalModificationText}}
                </p>
              </div>
            </div>
          </div>

          <ng-container *ngIf="request?.landNumbers?.length>0 && request?.requestType!==1">
            <h2 class="field__label"> رقم / اسم القطعة </h2>
            <div class="row mb-4 align-items-center">
              <div class="col-3" *ngFor="let land of request?.landNumbers.slice(0,3)">
                <div class="landCard">
                  <span class="landCard-text">{{land.number?land.number:land}}</span>
                </div>
              </div>
              <p class="col-3">
                <!--<button type="button" class="btn btn-sm btn-primary request-btn" (click)="isLandsModalOpen = true">
          استعراض عدد
          القطع ( {{request?.landNumbers?.length || 0}} )
        </button>-->
              </p>
              <abp-modal [(visible)]="isLandsModalOpen" [options]="{size: 'lg',modalDialogClass:'lands-modal'}">
                <ng-template #abpHeader>
                  <h5>عدد القطع ( {{request?.landNumbers?.length || 0}} )</h5>
                </ng-template>
                <ng-template #abpBody>
                  <div class="row land-wrapper justify-content-center">
                    <div class="col-8" *ngFor="let land of request?.landNumbers">
                      <div class="landCard">
                        <span class="landCard-text">{{land.number?land.number:land}}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </abp-modal>
            </div>
          </ng-container>

          <!-- *ngIf="request?.requestType==2" -->
          <div class="row" *ngIf="request?.instrumentNo && request?.requestType!==1">
            <div class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.instrumentNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.instrumentNo != request.instrumentNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.instrumentNo != request.instrumentNo) ?   'field__label icon icon-edit': 'field__label'">رقم الصك</h2>
                <p class="field__data">
                  {{request.instrumentNo}}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.instrumentDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.instrumentDate != request.instrumentDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.instrumentDate != request.instrumentDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الصك</h2>
                <p class="field__data">
                  {{request.instrumentDate | customDate}}
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.instrumentSource" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.instrumentSource != request.instrumentSource) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.instrumentSource != request.instrumentSource) ?   'field__label icon icon-edit': 'field__label'">مصدر الصك</h2>
                <p class="field__data">
                  {{request.instrumentSource}}
                </p>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateTypeText">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.realEstateTypeText || request.originalRequest?.realEstate.otherRealEstateType" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && (request.originalRequest?.realEstate.realEstateTypeText != request.realEstate.realEstateTypeText || request.originalRequest?.realEstate.otherRealEstateType != request.realEstate.otherRealEstateType)) ? 'hover' : ''" [class]="(request.originalRequest && (request.originalRequest?.realEstate.realEstateTypeText != request.realEstate.realEstateTypeText || request.originalRequest?.realEstate.otherRealEstateType != request.realEstate.otherRealEstateType)) ?   'field__label icon icon-edit': 'field__label'">وصف العقار</h2>
                <p class="field__data">
                  {{request.realEstate.realEstateTypeText || request.realEstate.otherRealEstateType}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateRegionName">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.regionName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.regionName != request.realEstate.regionName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.regionName != request.realEstate.regionName) ?   'field__label icon icon-edit': 'field__label'">المنطقة</h2>
                <p class="field__data">
                  {{request.realEstate.regionName}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateCityName">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.cityName" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.cityName != request.realEstate.cityName) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.cityName != request.realEstate.cityName) ?   'field__label icon icon-edit': 'field__label'">المدينة</h2>
                <p class="field__data">
                  {{request.realEstate.cityName}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestRealEstateAreaMeters">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.areaMeters" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.areaMeters != request.realEstate.areaMeters) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.areaMeters != request.realEstate.areaMeters) ?   'field__label icon icon-edit': 'field__label'">المساحه</h2>
                <p class="field__data">
                  {{request.realEstate.areaMeters}}
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="canCreateStatement || isEmirateRepresentative" class="row">
            <div class="col-md-3" *ngIf="canAccessRequestDocumentTypeText">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.documentTypeText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.documentTypeText != request.realEstate.documentTypeText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.documentTypeText != request.realEstate.documentTypeText) ?   'field__label icon icon-edit': 'field__label'">نوع الوثيقة</h2>
                <p class="field__data">
                  {{request.realEstate.documentTypeText}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestDocumentDate">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.realEstate.documentDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.documentDate != request.realEstate.documentDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.documentDate != request.realEstate.documentDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الوثيقة</h2>
                <p class="field__data">
                  {{request.realEstate.documentDate | customDate}}
                </p>
              </div>
            </div>


          </div>
          <div class="row" *ngIf="canCreateStatement || isEmirateRepresentative">
            <div class="col-md-12">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.isThereRequestForJudicialPossession ? 'نعم يوجد' : 'لا يوجد'" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.isThereRequestForJudicialPossession != request.isThereRequestForJudicialPossession) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.isThereRequestForJudicialPossession != request.isThereRequestForJudicialPossession) ?   'field__label icon icon-edit': 'field__label'">هل يوجد لديك طلب استحكام منظور قضائيا على نفس الطلب؟</h2>
                <p class="field__data">
                  {{request.isThereRequestForJudicialPossession ? 'نعم' : 'لا'}} يوجد
                </p>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="(canCreateStatement || isEmirateRepresentative) && request.isThereRequestForJudicialPossession && canAccessRequestCourtText">
            <div class="col-md-3">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.courtText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.courtText != request.courtText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.courtText != request.courtText) ?   'field__label icon icon-edit': 'field__label'">اسم المحكمة</h2>
                <p class="field__data">
                  {{request.courtText}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestCaseNo">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.caseNo" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.caseNo != request.caseNo) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.caseNo != request.caseNo) ?   'field__label icon icon-edit': 'field__label'">رقم الدعوى</h2>
                <p class="field__data">
                  {{request.caseNo}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestCaseDate">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.caseDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.caseDate != request.caseDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.caseDate != request.caseDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الدعوى</h2>
                <p class="field__data">
                  {{request.caseDate | customDate}}
                </p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="canAccessRequestCaseStatusText">
              <div class="field">
                <h2 [ngbTooltip]="request.originalRequest?.caseStatusText" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.caseStatusText != request.caseStatusText) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.caseStatusText != request.caseStatusText) ?   'field__label icon icon-edit': 'field__label'">حالة الدعوى</h2>
                <p class="field__data">
                  {{request.caseStatusText}}
                </p>
              </div>
            </div>

          </div>
          <hr *ngIf="request?.realEstate.isRealestateRevived !== null" />
          <div *ngIf="request?.realEstate.isRealestateRevived !== null" class="row">

            <ng-container *ngIf="request.realEstate.isRealestateRevived">
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.realEstate.isRealestateRevived ? 'نعم' : 'لا'" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.isRealestateRevived != request.realEstate.isRealestateRevived) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.isRealestateRevived != request.realEstate.isRealestateRevived) ?   'field__label icon icon-edit': 'field__label'">هل العقار تم إحياؤه؟</h2>
                  <p class="field__data">
                    {{request.realEstate.isRealestateRevived ? 'نعم' : 'لا'}}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.realEstate.revivalTypeText" placement="top" tooltipClass="abadi" [triggers]="isRevivalTypeTextChanged() ? 'hover' : ''" [class]="isRevivalTypeTextChanged() ?   'field__label icon icon-edit': 'field__label'">نوع الإحياء</h2>
                  <p class="field__data">
                    {{request.realEstate.revivalTypeText}}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.realEstate.revivalEvidenceText" placement="top" tooltipClass="abadi" [triggers]="isRevivalEvidenceTextChanged() ? 'hover' : ''" [class]="isRevivalEvidenceTextChanged() ?   'field__label icon icon-edit': 'field__label'">أسانيد الإحياء</h2>
                  <p class="field__data">
                    {{request.realEstate.revivalEvidenceText}}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="field">
                  <h2 [ngbTooltip]="request.originalRequest?.realEstate.revivalDate" placement="top" tooltipClass="abadi" [triggers]="(request.originalRequest && request.originalRequest?.realEstate.revivalDate != request.realEstate.revivalDate) ? 'hover' : ''" [class]="(request.originalRequest && request.originalRequest?.realEstate.revivalDate != request.realEstate.revivalDate) ?   'field__label icon icon-edit': 'field__label'">تاريخ الاحياء</h2>
                  <p class="field__data">
                    {{'::Enum:RevivalDate:'+request.realEstate.revivalDate|abpLocalization}}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>


        </ng-container>
      </div>
    </ng-container>
</app-request-inquiry>

