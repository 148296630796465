<div class="container-fluid px-0">
  <div class="app-wrapper">
    <div class="row no-gutters">
      <div class="col-3">
        <div class="header">
          <div class="header__logo">
            <abp-logo></abp-logo>
          </div>
        </div>
        <app-sidebar></app-sidebar>
      </div>
      <div class="col-9">
        <ng-content></ng-content>
        <div class="app-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
