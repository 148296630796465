import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-application-layout',
  templateUrl: './admin-application-layout.component.html',
  styleUrls: ['./admin-application-layout.component.scss']
})
export class AdminApplicationLayoutComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

}
