<div class="sidebar">
  <ul class="sidebar__links">
    <li class="sidebar__links__link">
      <a routerLink="/real-estate-requests/requests" routerLinkActive="active" *abpPermission="'SPGA.Requests.AdminRequests||SPGA.Requests.EngOfficeRequests||SPGA.Requests.InspectionRequests||SPGA.Requests.TechnicalRequests || SPGA.Requests.ShariaRequests || SPGA.Requests.SecretaryRequests || SPGA.Requests.InspectionSupervisorRequests ||SPGA.Requests.SpecializationStudyRequests || SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests || SPGA.Requests.RequestsStudyingMemberRequests|| SPGA.Requests.FormalSecretaryRequests || SPGA.Requests.GovernmentalSecretaryRequests'">
        <i class="icon icon-request"></i> الطلبات المسندة
      </a>
    </li>
    <li class="sidebar__links__link">
      <a routerLink="/real-estate-engineering-office-tasks" routerLinkActive="active" *abpPermission="'SPGA.Requests.Tasks'">
        <i class="icon icon-users"></i> مهامي
      </a>
    </li>
    <li class="sidebar__links__link">
      <a routerLink="/real-estate-requests/requests" routerLinkActive="active" *abpPermission="'SPGA.Requests.EmirateRepresentativeRequests'">
        <i class="icon icon-request"></i> طلبات المحدثين على الأراضي و المستفسر عنها من الإمارة
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.Ads'">
      <a routerLink="/real-estate-ads" routerLinkActive="active">
        <i class="icon icon-request"></i> الإعلانات
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.PublishedRequests'">
      <a [routerLink]="['/real-estate-requests', auditReqType[0].key]" routerLinkActive="active">
        <i class="icon icon-request"></i> الطلبات المنشورة
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.ApprovedRequests'">
      <a [routerLink]="['/real-estate-requests', auditReqType[1].key]" routerLinkActive="active">
        <i class="icon icon-request"></i> الطلبات المعتمدة من لجنة النظر
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.RequestsRecords'">
      <a [routerLink]="['/real-estate-requests', auditReqType[2].key]" routerLinkActive="active">
        <i class="icon icon-request"></i> محاضر الطلبات
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests','From'+ currentUserRole + 'ToInspectionMember']" routerLinkActive="active" *abpPermission="'SPGA.Requests.InspectionSupervisorRequests || SPGA.Requests.ApprovedRequests || SPGA.Requests.SaveConsiderationCommittee'">
        <i class="icon icon-request"></i>الطلبات تحت إفادة موظف إدارة الفحص
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests','From' + currentUserRole +'ToTechnicalMember']" routerLinkActive="active" *abpPermission="'SPGA.Requests.ApprovedRequests || SPGA.Requests.SaveConsiderationCommittee'">
        <i class="icon icon-request"></i>الطلبات تحت إفادة موظف إدارة فنية
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'From' + currentUserRole +'ToShariaMember']" routerLinkActive="active" *abpPermission="'SPGA.Requests.ApprovedRequests || SPGA.Requests.SaveConsiderationCommittee'">
        <i class="icon icon-request"></i>الطلبات تحت إفادة موظف إدارة دراسة الوثائق
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'From' + currentUserRole +'ToCommitteeSecretary']" routerLinkActive="active" *abpPermission="'SPGA.Requests.ApprovedRequests'">
        <i class="icon icon-request"></i>الطلبات تحت إفادة لجنة النظر المختصة
      </a>
    </li>
    <!--TODO-->
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'FromPreliminaryStudiesCommitteeSupervisorTo' + currentUserRole]" routerLinkActive="active" *abpPermission="'SPGA.Requests.SpecializationStudyRequests'">
        <i class="icon icon-request"></i>الطلبات المعادة من المشرف
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'From' + currentUserRole +'ToSPGAPublicUser']" routerLinkActive="active" *abpPermission="'SPGA.Requests.EngOfficeRequests||SPGA.Requests.InspectionRequests ||SPGA.Requests.TechnicalRequests || SPGA.Requests.ShariaRequests||SPGA.Requests.InspectionSupervisorRequests ||SPGA.Requests.SpecializationStudyRequests || SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests || SPGA.Requests.RequestsStudyingMemberRequests || SPGA.Requests.SaveConsiderationCommittee || SPGA.Requests.ApprovedRequests'">
        <i class="icon icon-users"></i> الطلبات تحت افادة المستفيد
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'FromPreliminaryStudiesCommitteeSupervisorTo' + currentUserRole]" routerLinkActive="active" *abpPermission="'SPGA.Requests.RequestsStudyingMemberRequests'">
        <i class="icon icon-request"></i>الطلبات المُحاله من مشرف لجنة الدراسات الأولية
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'From' + currentUserRole +'ToEngineeringOffice']" routerLinkActive="active" *abpPermission="'SPGA.Requests.InspectionRequests||SPGA.Requests.TechnicalRequests || SPGA.Requests.ShariaRequests||SPGA.Requests.InspectionSupervisorRequests'">
        <i class="icon icon-engineering"></i> الطلبات تحت افادة المكتب الهندسي
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'From' + currentUserRole +'ToMOJ']" routerLinkActive="active" *abpPermission="'SPGA.Requests.InspectionRequests||SPGA.Requests.TechnicalRequests || SPGA.Requests.ShariaRequests||SPGA.Requests.InspectionSupervisorRequests'">
        <i class="icon icon-judgment"></i> الطلبات تحت إفادة وزارة العدل
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'From' + currentUserRole +'ToPreliminaryStudiesCommitteeSupervisor']" routerLinkActive="active" *abpPermission="'SPGA.Requests.FormalSecretaryRequests ||SPGA.Requests.GovernmentalSecretaryRequests'">
        <i class="icon icon-request"></i>الطلبات تحت إفادة مشرف لجنة الدراسات الأولية
      </a>
    </li>

    <li class="sidebar__links__link" *abpPermission="'SPGA.Admin'">
      <a routerLink="/transfer-public-request" routerLinkActive="active">
        <i class="icon icon-request"></i> نقل الطلبات
      </a>
    </li>

    <li class="sidebar__links__link" *abpPermission="'SPGA.Admin'">
      <a routerLink="/users" routerLinkActive="active">
        <i class="icon icon-users"></i> إدارة المستخدمين
      </a>
    </li>

    <li class="sidebar__links__link" *abpPermission="'SPGA.Admin'">
      <a routerLink="/balady-zone" routerLinkActive="active">
        <i class="icon icon-users"></i> استعلام عن نطاق العقار
      </a>
    </li>
    <li class="sidebar__links__link">
      <a routerLink="/engineering-offices" routerLinkActive="active" *abpPermission="'SPGA.EngineeringOffices'">
        <i class="icon icon-engineering-office"></i> المكاتب الهندسية
      </a>
    </li>
    <li class="sidebar__links__link">
      <a routerLink="/bills-setting" routerLinkActive="active" *abpPermission="'SPGA.BillSettings'">
        <i class="icon icon-settings"></i> الاعدادات
      </a>
    </li>
    <li *ngIf="isDevelopment" class="sidebar__links__link">
      <a routerLink="/account/manage-profile" routerLinkActive="active" *abpPermission="'False'">
        <i class="icon icon-settings"></i> الاعدادات الشخصية
      </a>
    </li>
    <li class="sidebar__links__link" *ngIf="isDevelopment">
      <a routerLink="/identity/roles" routerLinkActive="active" *abpPermission="'AbpIdentity.Roles'">
        <i class="icon icon-settings"></i> الصلاحيات
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.RequestsReadyToBePublished'">
      <a routerLink="/real-estate-requests/requests" routerLinkActive="active">
        <i class="icon icon-request"></i> طلبات جاهزة للنشر
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests'">
      <a [routerLink]="['/real-estate-requests', 'From' + currentUserRole +'ToSpecializationStudyMember']" routerLinkActive="active">
        <i class="icon icon-request"></i>طلبات تحت افادة عضو دراسة الإختصاص
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests'">
      <a [routerLink]="['/real-estate-requests', preliminaryStudiesCommitteeSupervisorFilters[0].key]" routerLinkActive="active">
        <i class="icon icon-request"></i> الطلبات المعتمدة
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests'">
      <a [routerLink]="['/real-estate-requests', preliminaryStudiesCommitteeSupervisorFilters[1].key]" routerLinkActive="active">
        <i class="icon icon-request"></i>  الطلبات تحت الدراسة
      </a>
    </li>
    <li class="sidebar__links__link">
      <a [routerLink]="['/real-estate-requests', 'FromInspectionSupervisorTo' + currentUserRole]" routerLinkActive="active" *abpPermission="'SPGA.Requests.InspectionRequests'">
        <i class="icon icon-request"></i>الطلبات المعادة من المشرف
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests'">
      <a [routerLink]="['/real-estate-requests', 'FromCommitteeSecretaryTo'+currentUserRole , reqPath[1].key]" routerLinkActive="active">
        <i class="icon icon-request"></i>  الطلبات المعادة من لجنة النظر الشكلية
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests'">
      <a [routerLink]="['/real-estate-requests', 'FromCommitteeSecretaryTo'+currentUserRole , reqPath[2].key]" routerLinkActive="active">
        <i class="icon icon-request"></i>  الطلبات المعادة من لجنة النظر الحكومية
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.InspectionRequests || SPGA.Requests.TechnicalRequests || SPGA.Requests.ShariaRequests'">
      <a [routerLink]="['/real-estate-requests', 'FromCommitteeSecretaryTo'+currentUserRole]" routerLinkActive="active">
        <i class="icon icon-request"></i>الطلبات المعادة من لجنة النظر المختصة
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.InspectionRequests || SPGA.Requests.TechnicalRequests || SPGA.Requests.ShariaRequests || SPGA.Requests.SecretaryRequests'">
      <a [routerLink]="['/real-estate-requests', 'FromAuditCommitteeSecretaryTo'+currentUserRole]" routerLinkActive="active">
        <i class="icon icon-request"></i>الطلبات المعادة من لجنة المراجعة
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.InspectionRequests || SPGA.Requests.InspectionSupervisorRequests || SPGA.Requests.TechnicalRequests || SPGA.Requests.ShariaRequests || SPGA.Requests.PreliminaryStudiesCommitteeSupervisorRequests || SPGA.Requests.RequestsStudyingMemberRequests || SPGA.Requests.SpecializationStudyRequests'">
      <a [routerLink]="['/real-estate-requests', auditReqType[4].key]" routerLinkActive="active">
        <i class="icon icon-request"></i>قائمة الطلبات منتهية المهلة
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Admin'">
      <a routerLink="/statement-management" routerLinkActive="active">
        <i class="icon icon-users"></i> إدارة نظام الإفادة
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.ReassignRequests'">
      <a routerLink="/reassign-requests" routerLinkActive="active">
        <i class="icon icon-users"></i> إدارة إعادة تعيين الطلبات
      </a>
    </li>

    <li class="sidebar__links__link" *abpPermission="'SPGA.Admin'">
      <a routerLink="/reassign-status" routerLinkActive="active">
        <i class="icon icon-users"></i> حالة إعادة التعيين
      </a>
    </li>
    <li class="sidebar__links__link" *abpPermission="'SPGA.Requests.AdminRequests'">
      <a routerLink="/objections" routerLinkActive="active">
        <i class="icon icon-request"></i> الاعتراضات
      </a>
    </li>
    <li class="sidebar__links__link">
      <a (click)="logout()" routerLinkActive="active" href="javascript:">
        <i class="icon icon-power"></i> تسجيل خروج
      </a>
    </li>

    <li class="sidebar__links__link mt-4" *abpPermission="'SPGA.ContactInfo'">
      <!--Fixme: replace with right permission-->
      <button class="btn btn-outline-secondary" routerLink="/engineering-office-edit-profile">
        <i class="icon icon-edit-note mr-2"></i>
        تعديل الحساب
      </button>
    </li>
  </ul>
    <div class="sidebar__footer">
        <div class="sidebar__footer__copyright">جميع الحقوق محفوظة &copy; الهيئة العامة لعقارات الدولة {{date}}</div>
        <div class="sidebar__footer__social">
            <a href="https://twitter.com/ehkaam_sa?s=21" class="sidebar__footer__social__link"><i class="icon icon-twitter"></i></a>
            <!-- <a href="/" class="sidebar__footer__social__link"><i class="icon icon-instagram"></i></a>
      <a href="/" class="sidebar__footer__social__link"><i class="icon icon-linkedin"></i></a> -->
            <a href="https://youtu.be/Y6lEVwIMw4c" class="sidebar__footer__social__link"><i class="icon icon-youtube"></i></a>
            <!-- <a href="/" class="sidebar__footer__social__link"><i class="icon icon-at"></i></a> -->
        </div>
    </div>
</div>
