<form [formGroup]="form">
    <div class="row">
        <div class="col-12 col-md-3">
            <span class="info__data">
                <label class="form-inputs__title" for="name">
                    اسم العضو
                </label>
            </span>
            <div class="form-group  form-inputs">
                <input [maxLength]=250 aria-describedby="emailHelp" class="form-control  form-inputs__input" formControlName="name" [matTooltipPosition]="'above'" [matTooltip]="form.controls.name.value" id="name" placeholder=" فضلا اكتب اسم العضو " type="text" value="">
            </div>
        </div>
        <div class="col-12  col-md-3">
            <div class="form-group">
                <label for="formalGovOrganizationName">اسم الجهة</label>
                <ng-select [items]="formalGovOrganizationName" bindLabel="key" bindValue="value" [matTooltipPosition]="'above'" [matTooltip]="translateSelectedValue('::Enum:FormalGovOrganizationName:' , [form.controls.formalGovOrganizationName.value])" formControlName="formalGovOrganizationName" id="formalGovOrganizationName" placeholder="اسم الجهة">
                    <ng-container>
                        <ng-template let-index="index" let-item="item" ng-option-tmp>
                            <div [matTooltipPosition]="'right'" [matTooltip]="translateSelectedValue('::Enum:FormalGovOrganizationName:' , [item['value']])">
                                {{(('::Enum:FormalGovOrganizationName:' + item['value']) | abpLocalization)}}
                            </div>
                        </ng-template>
                        <ng-template let-item="item" ng-label-tmp>
                                {{(('::Enum:FormalGovOrganizationName:' + item['value']) | abpLocalization)}}
                        </ng-template>
                    </ng-container>
                </ng-select>
            </div>
        </div>
        <div class="col-12  col-md-3">
            <div class="form-group">
                <label for="formalGovMainReserveMember">عضو اساسي/احتياطي</label>
                <ng-select [items]="mainReserveMember" bindLabel="key" bindValue="value" formControlName="formalGovMainReserveMember" id="formalGovMainReserveMember" placeholder="عضو اساسي/احتياطي">
                    <ng-container>
                        <ng-template let-index="index" let-item="item" ng-option-tmp>
                            {{(('::Enum:MainReserveMember:' + item['value']) | abpLocalization)}}
                        </ng-template>
                        <ng-template let-item="item" ng-label-tmp>
                            {{(('::Enum:MainReserveMember:' + item['value']) | abpLocalization)}}
                        </ng-template>
                    </ng-container>
                </ng-select>
            </div>
        </div>
        <div class="col-12  col-md-3">
            <div class="form-group">
                <label for="vote">الصوت</label>
                <ng-select (change)="memberVoteChange($event)" [items]="memberVote" bindLabel="key" bindValue="value" formControlName="vote" id="vote" placeholder="اختيار الصوت">
                    <ng-container>
                        <ng-template let-index="index" let-item="item" ng-option-tmp>
                            {{(('::Enum:MemberVote:' + item['value']) | abpLocalization)}}
                        </ng-template>
                        <ng-template let-item="item" ng-label-tmp>
                            {{(('::Enum:MemberVote:' + item['value']) | abpLocalization)}}
                        </ng-template>
                    </ng-container>
                </ng-select>
            </div>
        </div>
        <div *ngIf="form.controls.vote.value == MemberVote.Disapprove" class="col-12 col-md-3">
            <span class="info__data">
                <label class="form-inputs__title" for="disapprovalReason">
                    سبب عدم الموافقة
                </label>
            </span>
            <div class="form-group  form-inputs">
                <input maxlength="250"  aria-describedby="emailHelp" class="form-control  form-inputs__input" formControlName="disapprovalReason" id="disapprovalReason" placeholder=" فضلا اكتب سبب عدم الموافقة" type="text">
            </div>
        </div>
        <div *ngIf="form.controls.vote.value == MemberVote.Abstaining" class="col-12  col-md-3">
            <div class="form-group">
                <label for="formalGovAbstainingReason">سبب الامتناع</label>
                <div class="form-group  form-inputs">
                    <input maxlength="250" aria-describedby="emailHelp" class="form-control  form-inputs__input" formControlName="formalGovAbstainingReason" id="formalGovAbstainingReason" placeholder=" فضلا اكتب سبب عدم الإمتناع" type="text">
                </div>
            </div>
        </div>
    </div>
</form>
<!-- {{this.form.value | json}} -->