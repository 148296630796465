import { Component, OnInit } from '@angular/core';
import { AppAuthService } from '@spga-shared/providers/auth/app-auth-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userProfileEnable = false;

  constructor(private authService: AppAuthService) {
  }

  ngOnInit(): void {
    this.userProfileEnable = this.authService.currentLoggedInUser$ != null;
  }

}
