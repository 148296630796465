import { Component, OnInit } from '@angular/core';
import { AppAuthService } from '@spga-shared/providers/auth';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  constructor(private authService: AppAuthService) {
  }

  currentUser: any;
  role: string = '';
  ngOnInit(): void {
    this.currentUser = this.authService.currentLoggedInUser$;
    if (this.currentUser.roles[0] == 'admin') { this.role = 'مدير النظام'; }
    else if (this.currentUser.roles[0] == 'TransferPublicRequest') { this.role = 'مدير  نقل الطلبات'; }
    else if (this.currentUser.roles[0] == 'EngineeringOffice') { this.role = 'مكتب هندسي'; }
    else if (this.currentUser.roles[0] == 'InspectionMember') { this.role = 'عضو إدارة الفحص'; }
    else if (this.currentUser.roles[0] == 'TechnicalMember') { this.role = 'عضو الإدارة الفنية'; }
    else if (this.currentUser.roles[0] == 'ShariaMember') { this.role = 'عضو إدارة دراسة الوثائق'; }
    else if (this.currentUser.roles[0] == 'NormalCommitteeSecretary') { this.role = 'سكرتير لجنة النظر في الطلبات الطبيعية'; }
    else if (this.currentUser.roles[0] == 'FormalCommitteeSecretary') { this.role = 'سكرتير لجنة النظر في الطلبات الشكلية'; }
    else if (this.currentUser.roles[0] == 'AwqafCommitteeSecretary') { this.role = 'سكرتير لجنة النظر في طلبات هيئة الأوقاف'; }
    else if (this.currentUser.roles[0] == 'GovernmentCommitteeSecretary') { this.role = 'سكرتير لجنة النظر في الطلبات الحكومية'; }
    else if (this.currentUser.roles[0] == 'PublishingUnitAndObjectionsEmployee') { this.role = 'موظف وحدة النشر والاعتراضات'; }
    else if (this.currentUser.roles[0] == 'AuditCommitteeSecretary') { this.role = 'أمين لجنة المراجعة'; }
    else if (this.currentUser.roles[0] == 'InspectionSupervisor') { this.role = 'مشرف إدارة الفحص'; }
    else if (this.currentUser.roles[0] == 'SpecializationStudyMember') { this.role = 'عضو دراسة الإختصاص'; }
    else if (this.currentUser.roles[0] == 'PreliminaryStudiesCommitteeSupervisor') { this.role = 'مشرف لجنة الدراسات الأولية'; }
    else if (this.currentUser.roles[0] == 'RequestsStudyingMember') { this.role = 'عضو دراسة الطلبات'; }
    else if (this.currentUser.roles[0] == 'EmirateRepresentative') { this.role = 'ممثل الإمارة'; }
    else if (this.currentUser.roles[0] == 'SupervisorOfTheTechnicalDepartment') { this.role = 'مشرف الإدارة الفنية'; }
    else if (this.currentUser.roles[0] == 'SupervisorOfTheShariaAdministration') { this.role = 'مشرف إدارة دراسة الوثائق'; }

  }
}
